.button-block {
  display: flex;
  align-items: center;
}

.load-icon-view {
  width: 19px;
  height: 20px;
  margin-right: 10px;
  display: block;
}
.load-icon {
  display: none;
}

.button-content {
  display: flex;
  align-items: end;
  margin: 0 auto;
}

.load-icon-view {
  animation: spin 2s linear 0s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
