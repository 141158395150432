@import 'src/assets/styles/index.scss';

.section-information-pages {
  @include content-container();
  font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(18px + (30 - 18) * ((100vw - 375px) / (1920 - 375)));
  margin-bottom: calc(24px + (125 - 24) * ((100vw - 375px) / (1920 - 375)));
  color: $label-reverse;
  min-height: 50vh;

  .spinner-wrapper {
    display: flex;
    justify-content: center;
  }

  .information-pages__title {
    @include header-two();
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  p {
    font-family: 'Roboto-Regular';
    font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
    padding-top: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
    line-height: normal;

    h3, h2, h1, strong {
      font-family: Raleway-Bold,sans-serif;
      font-size: 2.25rem;
      margin-bottom: 10px;
      margin-top: 20px;
    }

    a {
      color: $primary-red;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  h3, h2, h1 {
    font-family: 'Roboto-Medium';
    margin-bottom: 5px;
    line-height: normal;
  }

  ul {
    list-style: disc;
    margin: 20px auto 20px 40px;
  }

  iframe {
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
}

.pink-line-support {
  width: 30%;
  height: 4px;
  background-color: $primary-red;
  margin-top: 30px;
}
