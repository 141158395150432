body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#shareasaleImg {
  width: 1px;
  height: 1px;
}
