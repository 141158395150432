@import '../../../assets/styles/index.scss';
@import 'src/assets/styles/mixins/media';

.navigation-menu {
  display: flex;
  flex: 1;
  justify-content: space-between;
  color: $label-reverse;
  padding: 0 20px;
  max-width: 900px;
  align-items: center;

  @media (max-width: 1200px) {
    flex: initial;
  }
}

.header-link-navigation {
  text-align: center;
  display: inline;
  transition: 0.2s linear;
  font-family: Raleway-Medium, sans-serif;
  color: #051431;
  font-size: 14px;

  &.red {
    color: $primary-red;
  }

  &:hover {
    color: $light-red;
  }
  &:active {
    color: $primary-red;
  }
  @media (max-width: 1200px) {
    text-align: start;
    display: none;
  }
}

.header-link-bag {
  font-family: Raleway-Medium, sans-serif;
  color: #051431;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color .3s;
  font-size: 14px;

  &--icon {
    position: relative;
    margin-right: 10px;

    img {
      width: auto;
      height: 20px;
    }

    &--count {
      background-color: #fd8d78;
      height: 15px;
      min-width: 15px;
      border-radius: 15px;
      text-align: center;
      position: absolute;
      right: -7px;
      top: -6px;
      color: #000;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &:hover {
    color: $primary-red;
  }
}

.modal-header-nav {
  @media (max-width: 1200px) {

    .header-link-navigation {
      display: flex;
      width: 100%;
      padding: 15px 15px;
      margin-bottom: 10px;
      background-color: #eee;
      border-radius: 12px;
      font-size: 16px;
    }

  }
}
