@import 'src/assets/styles/index.scss';
@import 'src/assets/styles/mixins/media.scss';

.plans__cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include below-breakpoint(md) {
    justify-content: center;
  }
}

.plans__card-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: calc(320px + (362 - 320) * ((100vw - 375px) / (1920 - 375)));
  margin-bottom: 56px;

  @include below-breakpoint(sm) {
    margin-bottom: 0;
  }
}

.plan-type-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include below-breakpoint(sm) {
    flex-direction: column;
  }

  .plan-type-tab-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 0 0 1px 1px #eee;
    margin: 20px 20px;
    font-family: 'Raleway-Bold', sans-serif;
    width: 250px;
    transition: background-color .3s, color .3s;

    &.active {
      background-color: #d75f5c;
      color: #fff;
    }

    @include below-breakpoint(sm) {
      margin: 10px 0px;
    }
  }
}

.plans-coming-soon {
  font-size: 30px;
  font-family: 'Raleway-Bold', sans-serif;
  color: #d75f5c;
}
