@import 'src/assets/styles/index';
@import 'src/assets/styles/mixins/media';

.restore-password-email {
  margin-bottom: 250px;
}

.restore-password-email__header {
  font-family: "Raleway-Bold", sans-serif;
  font-size: 4rem;
  margin-bottom: 80px;
  text-align: center;

  @include below-breakpoint(xs) {
    margin-bottom: 48px;
    font-size: 2rem;
  }
}

.restore-password-email__subtitle {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-bottom: 48px;
  text-align: center;
}

.restore-password-email__subtitle.resend {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
}

.restore-password-email__resend-button {
  color: $light-blue;
  text-decoration: underline;

  &:hover {
    color: $dark-blue;
  }
}