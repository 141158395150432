@import 'src/assets/styles/index.scss';
@import 'src/assets/styles/mixins/media.scss';
@import 'src/assets/styles/mixins/button.scss';

.basket {
  margin-top: 61px;
  margin-bottom: 70px;
}

.basket__title {
  font-family: "Raleway-Extra-Bold", sans-serif;
  font-size: 2.25rem;
}

.basket__sub-title {
  font-family: Raleway-Medium, sans-serif;
  font-size: 18px;
  margin-bottom: 40px;
}

.basket__products-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 50px;

  @media (max-width: 1040px) {
    gap: 0;
    flex-direction: column;
    align-items: center;
  }
}

.basket__products {}

.basket__products-list {
  flex: 1 1 calc(100% - 460px);
  @media (max-width: 1040px) {
    width: 100%;
  }
}

.basket__products-list-item {
  margin-bottom: 48px;

  @include below-breakpoint(sm) {
    margin-bottom: 24px;
  }
}

.basket__basket-item {
  margin-bottom: 48px;

  @include below-breakpoint(sm) {
    margin-bottom: 24px;
  }
}

.basket__price-container {
  width: 100%;
  padding: 25px 30px;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid rgba(5, 20, 49, 0.2);
}

.basket__price-row {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1.125rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.basket__price-row.total {
  font-family: "Raleway-Extra-Bold", sans-serif;
  font-size: 22px;
  margin-bottom: 0;
  color: #051431;

  .basket__price-col.sum {
    font-family: "Raleway-Semi-Bold", sans-serif;
    font-size: 26px;
  }
}

.basket__price-row.discount {
  font-family: "Raleway-Extra-Bold", sans-serif;
  font-size: 18px;
  margin-bottom: 0;
  color: #051431;

  .basket__price-col.sum {
    font-family: "Raleway-Semi-Bold", sans-serif;
    font-size: 20px;
  }
}

.basket__price-row.taxes {
  display: block;
}

.basket__price-row__taxes-text {
  font-family: "Raleway-Bold", sans-serif;
  font-size: 0.8rem;
  text-align: end;
}

.basket__price-col {
  display: flex;
  flex-direction: column;

  small {
    font-family: Raleway-Medium, sans-serif;
    font-size: 10px;
  }
}

.basket__price-col.sum {
  font-family: "Raleway-Medium", sans-serif;
}

.basket__button-next {
  @include button-red($padding: 20px 48px);
  width: 100%;
  margin-top: 40px;
}

.basket__price-row__policy-text {
  font-size: 15px;
  font-family: Raleway-Regular, sans-serif;
  margin-top: 20px;
  color: #051431;
  a {
    text-decoration: underline;
  }
}

.basket__price-col {
  &.coupon {
    height: auto;
  }

  &__coupon-title {
    font-family: "Raleway-Medium", sans-serif;
    font-size: 40px;
    color: #051431;
    margin-bottom: 30px;
  }

  &__coupon-input {
    width: 100%;
    margin-top:10px;
     input {
       background-color: #ececec;
       padding-right: 70px!important;
       border-radius: 50px;
       border: 0;
     }
  }

  &__coupon-form {
    position: relative;
  }

  &__coupon-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #d75f5c;
    font-family: "Raleway-Bold", sans-serif;
  }

  &__coupon-form-wrapper {
    min-height: 60px;
    position: relative;
    margin-bottom: 20px;

    &--title {
      color: #051431;
      font-family: Raleway-Bold, "sans-serif";
      font-size: 22px;
      margin-bottom: 10px;
    }
  }

  &__coupon-spinner {
    width: 100%;

    .lds-ring {
      position:absolute;
      left: 50%;
      top: 50%;
      transform:translate(-50%, -50%);
      width: 40px;
      height: 40px;

      div {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__coupon-active {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 1px 1px #eee;
    margin: 10px 0;
    padding: 10px 30px 10px 10px;
    position: relative;

    &-cross {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      background-color: #eee;
      border-radius: 50%;

      svg {
        transform: scale(.6);
      }
    }

    &-text {
      font-family: "Raleway-Medium", sans-serif;
      color: #051431;
      b {
        font-family: "Raleway-Bold", sans-serif;
      }
    }
  }
}

.basket__price-row__ptb {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.basket__price-row__ptb__img {
  width: auto;
  max-height: 100px;
}

.basket__money-back {
  width: 100%;
  margin-top: 25px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 25px;

  &__icon {
    position: relative;
    img {
      width: 60px;
    }
    &__text {
      font-size: 13px;
      color: #fff;
      text-align: center;
      font-family: "Roboto-Regular", sans-serif;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      b {
        font-family: "Roboto-Bold", sans-serif;
      }
    }
  }

  &__text {
    color: #333;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 16px;
    margin-left: 15px;
  }
}

.basket__products-props {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 15px;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 60px;
    }
    span {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 13px;
      margin-left: 10px;
    }

    @include below-breakpoint(lg) {
      flex-direction: column;
      text-align: center;
    }

    @include below-breakpoint(sm) {
      margin-bottom: 20px;
    }
  }

  @include below-breakpoint(sm) {
    flex-direction: column;
  }
}

.basket__sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  left: 0;
  height: 1000px;

  @media (max-width: 768px) {
    display: none;
  }
}

.basket__right {
  flex: 1 1 362px;
}
