.change-plan-container {
  &--spinner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);

    &.spinner-wrapper {
      margin: 0;
    }

    .lds-ring {
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
}
