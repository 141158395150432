@import 'src/assets/styles/index.scss';
@import 'src/assets/styles/mixins/media.scss';
@import 'src/assets/styles/mixins/container.scss';

.auth-screen {
  @include content-container();
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  font-family: "Roboto-Bold", sans-serif;
}

.auth-screen__header {
  font-family: "Raleway-Bold", sans-serif;
  font-size: 3.5rem;
  margin-bottom: 24px;
}