@import 'src/assets/styles/index.scss';

.account-tariff-info-show-qr-wrapper {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    font-family: 'Raleway-Bold';
    font-size: 18px;
    color: $label-reverse;
  }

  &__code {
    margin-top: 30px;
  }

  &__link-generate {
    @include button-red();
    font-family: 'Raleway-Medium', sans-serif;
    margin-top: 30px;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
  }

  &__img {
    width: 300px;
    height: 300px;
  }
}
