.account-screen-block {
  margin-top: 50px;
}

.account-screen-form__block-switch {
  width: 100%;
  margin-bottom: 60px;
}

.account-screen-form__block-inputs {
  width: 100%;
  display: flex;
}
