.hwd-tabs {

  &--headers {
    display: flex;
    grid-gap: 30px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(5, 20, 49, 0.2);
  }

  &--header {
    padding: 15px 5px;
    font-family: Raleway-Bold, "sans-serif";
    font-size: 28px;
    color: rgba(#000, .5);
    transition: color .3s;
    cursor: pointer;

    &:hover, &.active {
      color: #000;
    }
  }

  &--content {

  }

  &--html-content {
    font-family: Roboto-Regular, "sans-serif";
    font-size: 18px;
    padding: 10px 20px;
  }

  &--spec-content {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
  }

  &--spec-item {
    width: calc(50% - 10px);
    display: flex;
    padding: 30px 0;
    border-bottom: 1px solid rgba(5, 20, 49, 0.2);
    justify-content: space-between;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  &--spec-left {
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    font-family: Raleway-Bold, "sans-serif";
    font-size: 24px;
    padding-right: 5px;

    img {
      width: auto;
      height: 50px;
      margin-right: 10px;
    }

    @media (max-width: 690px) {
      font-size: 20px;

      img {
        height: 35px;
      }
    }
  }

  &--spec-right {
    width: calc(100% - 220px);
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    justify-content: center;

    &--item {
      font-family: Roboto-Regular, "sans-serif";
      font-size: 18px;
    }

    @media (max-width: 690px) {
      &--item {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 600px) {
    &--spec-content {
      grid-gap: 0;
    }
    &--spec-left, &--spec-right {
      width: 100%;
    }
    &--spec-left {
      margin-bottom: 15px;

      img {
        height: 30px;
      }
    }
    &--spec-item {
      flex-direction: column;
      padding: 15px 0;
    }
  }

  &--dropdowns {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }

  &--dropdown {
    width: 100%;

    &--title {
      font-family: Raleway-Bold, "sans-serif";
      font-size: 18px;
      color: #051431;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 10px 0;
      border-bottom: 1px solid rgba(5, 20, 49, 0.2);
      transition: border-bottom-color .3s;

      img {
        width: 25px;
        height: 25px;
        transition: transform .3s;
      }

      &:hover {
        border-bottom-color: #051431;
      }
    }

    &--content {
      display: none;
      width: 100%;
      padding: 10px 0;
    }

    &.active {
      .hwd-tabs--dropdown {
        &--title {
          border-bottom-color: #051431;

          img {
            transform: rotate(-180deg);
          }
        }
        &--content {
          display: block;
        }
      }
    }
  }
}
