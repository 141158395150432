@import 'src/assets/styles/index.scss';

.ashd {

  &--container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: calc(100% - 324px);

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  &--spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
  }

  &--title {
    @include header-two();
    margin-bottom: 30px;
  }

  &--content {
    display: flex;
    margin-bottom: 30px;
    grid-gap: 20px;

    &--left {
      width: 60%;
    }

    &--right {
      width: 40%;
      display: flex;
      justify-content: center;
    }

    @media (max-width: 992px) {
      flex-direction: column;

      &--left, &--right {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  &--content-items {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }

  &--content-image {
    width: 100%;
    max-width: 400px;
    min-height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &--content-item {
    width: 100%;
    padding: 7px;
    border-radius: 12px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 4px 0 #d75f5c;

    &--title {
      font-family: Raleway-Bold, "sans-serif";
      font-size: 18px;
      color: #000;
      padding-left: 5px;
    }

    &--value {
      font-family: Roboto-Regular, sans-serif;
      font-size: 16px;
      color: #000;
      height: 100%;
      padding: 10px 15px;
      background-color: #eee;
      border-radius: 10px;
    }
  }
}
