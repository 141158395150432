.pagination-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.pagination {
  display: flex;
  border-radius: 0.25rem;
  grid-gap: 10px;
}

.pagination-item, .pagination-button {
  position: relative;
  padding: 0.5rem 1rem;
  line-height: 1.25;
  color: #000;
  background-color: #ECDFCE;
  margin-left: -1px;
  cursor: pointer;
  transition: border-color .3s;
  border-radius: 4px;
  border: 1px solid transparent;
  font-family: "Roboto-Regular", sans-serif;

  &:hover {
    border-color: rgba(5, 20, 49, 0.2);
  }

  &-active {
    border-color: rgba(5, 20, 49, 0.2);
  }

  &:disabled {
    pointer-events: none;
    opacity: .5;
  }

  &-prev {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &-next {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.pagination-ellipsis {
  cursor: default;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  background-color: transparent;

  span {
    width: 5px;
    height: 5px;
    background-color: #ECDFCE;
    border-radius: 100%;
  }
}
