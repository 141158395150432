.hwl-checkbox {
  padding: 10px 0;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &--box {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid rgba(220, 220, 228, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .3s;

    &.checked {
      background-color: rgba(5, 20, 49, 1);
      border: 0;
    }
  }

  &--check-mark {
    width: 10px;
    height: 10px;
  }

  &--label {
    font-family: Roboto-Regular, sans-serif;
    font-size: 16px;
    color: #000;
    margin-left: 10px;
  }
}
