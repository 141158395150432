.pwt-contact {
  display: flex;
  padding: 60px 50px;
  grid-gap: 30px;
  background-repeat: no-repeat!important;
  background-size: cover!important;

  &--left-side {
    flex: 1 30%;
  }

  &--right-side {
    flex: 1 70%;
  }

  &--title {
    font-family: Raleway-Bold, "sans-serif";
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  &--subtitle {
    font-family: Roboto-Regular, "sans-serif";
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
  }

  &--item {
    display: flex;
    align-items: center;
  }

  &--item-icon {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }

  &--item-title {
    font-family: Roboto-Regular, "sans-serif";
    font-size: 18px;
    color: #fff;
  }

  &--form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    margin-top: 30px;
  }

  &--form-item {
    flex: 1 48%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    .styled-input {
      width: 100%;
    }

    .styled-input__label {
      color: #fff;
    }

    .styled-input__input {
      background-color: #fff;
    }
  }

  &--button {
    width: 100%;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D75F5C;
    border-radius: 10px;
    font-family: Raleway-Bold, "sans-serif";
    font-size: 16px;
    color: #fff;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    &--left-side, &--right-side {
      flex: 1 100%;
    }
  }

  @media (max-width: 610px) {
    padding: 30px;

    &--form {
      flex-direction: column;
    }

    &--form-item {
      width: 100%;
    }
  }
}
