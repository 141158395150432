@import 'src/assets/styles/index.scss';
@import 'src/assets/styles/mixins/text';
@import 'src/assets/styles/mixins/heading';
@import 'src/assets/styles/mixins/button.scss';
@import 'src/assets/styles/mixins/container.scss';

.card {
  display: flex;
  flex-direction: column;
  background-color: $label;
  transition: all 0.5s linear;
  width: calc(320px + (362 - 320) * ((100vw - 375px) / (1920 - 375)));
  margin: 12px 12px 40px 12px;
  color: $primary-dark-blue;
  border: 1px solid rgba(5, 20, 49, .2);
  border-radius: 20px;

  &.disable {
    pointer-events: none;
    opacity: .5;
  }

  &__content {
    text-align: center;
    margin-bottom: 24px;
  }

  &__title {
    font-family: 'Raleway-Bold', sans-serif;
    font-size: calc(28px + (24 - 24) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(24px + (32 - 24) * ((100vw - 375px) / (1920 - 375)));
    margin-bottom: 24px;
  }

  &__total-price {
    font-family: 'Raleway-Bold', sans-serif;
    font-size: calc(32px + (56 - 32) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(40px + (64 - 40) * ((100vw - 375px) / (1920 - 375)));
    letter-spacing: 1px;
    margin-bottom: 8px;
  }

  &__total-discount-price {
    font-family: 'Raleway-Bold', sans-serif;
    font-size: calc(32px + (56 - 32) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(40px + (64 - 40) * ((100vw - 375px) / (1920 - 375)));
    letter-spacing: 1px;
    margin-bottom: 8px;

    .ctd-price {
      position: relative;
      margin-right: 10px;

      &::before {
        content: '';
        width: 100%;
        height: 7px;
        background: #d75f5c;
        position: absolute;
        transform: rotate(30deg);
        top: 50%;
      }
    }

    .ctd-new-price {
      color: #d75f5c;
    }
  }

  &__split-price {
    @include text-card();
    margin-bottom: 24px;
  }

  &__description {
    @include text-card();
    margin-bottom: 24px;
    word-wrap: break-word;
  }

  &__characteristics {
    @include text-card();
    width: fit-content;
    text-align: left;
    margin: 0 auto;
  }

  &__characteristics-item {
    margin-bottom: 12px;

    &:before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      background: $primary-red;
      border-radius: 50%;
      margin-right: 8px;
      vertical-align: middle;
    }

    span {
      color: #d75f5c;
    }
  }

  &__button {
    @include button-red($padding: 18px 48px);
    width: fit-content;
    text-align: center;
    margin: auto auto 0 auto;
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &:hover, &.darken {
    background-color: $primary-dark-blue;
    color: $label;
  }
  @media (max-width: 700px) {
    margin: 12px auto;
  }

  &__link {
    margin: auto auto 0 auto;
  }

  &__extra {
    text-align: center;
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 18px;
    line-height: 24px;

    i {
      font-style: italic;
      font-size: 14px;
    }
  }

  &__minmonth {
    text-align: center;
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 13px;
    margin-top: 15px;
  }
}
.card__conditions {
  font-family: 'Roboto-Regular';
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 24px auto;

  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.card__conditions_item__value {
  font-family: 'Roboto-Medium';
  color: #d75f5c;
}

.card__conditions_item__sub {
  font-size: 16px;
}

.card__collapser {
  cursor: pointer;
  font-family: "Roboto-Medium", sans-serif;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    display: inline-block;
    margin-left: 10px;
  }
}
