@import 'src/assets/styles/index';
@import 'src/assets/styles/mixins/media';
@import 'src/assets/styles/mixins/button';

.reset-password-form {
  max-width: 500px;
  width: 100%;
  margin-bottom: 125px;
}

.reset-password-form__header {
  font-family: "Raleway-Bold", sans-serif;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 80px;

  @include below-breakpoint(xs) {
    font-size: 2rem;
    margin-bottom: 48px;
  }
}

.reset-password-form__subtitle {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 48px;
  text-align: center;
}

.reset-password-form__form {
  margin: 0 auto;
}

.reset-password-form__inputs-container {
}

.reset-password-form__input {
  margin-bottom: 48px;
  width: 100%;
}

.reset-password-form__submit {
  @include button-red($padding: 20px 48px);
  display: block;
  margin: 0 auto;
  text-align: center;
}