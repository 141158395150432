@import 'src/assets/styles/index.scss';

// Form
.account-screen-form__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: calc(44px + (64 - 44) * ((100vw - 375px) / (1920 - 375)));

  @include below-breakpoint(sm) {
    justify-content: center;
  }
}

.account-screen-edit__input {
  max-width: 362px;
  width: calc(50% - 12px);
  margin-bottom: calc(44px + (68 - 44) * ((100vw - 375px) / (1920 - 375)));

  &:nth-child(odd) {
    margin-right: 24px;

    @include below-breakpoint(sm) {
      margin-right: 0;
    }
  }

  @include below-breakpoint(sm) {
    max-width: 327px;
    width: 100%;
  }
}
