@import 'src/assets/styles/index.scss';
@import 'src/assets/styles/mixins/button.scss';
@import 'src/assets/styles/mixins/media.scss';

.sign-in-multi-factor {
  max-width: 595px;
  margin-bottom: 125px;
  width: 100%;

  &__email-container {
    margin-top: 10px;
    margin-bottom: 30px;
    box-shadow: 0 0 1px 1px #eee;
    padding: 20px;
    border-radius: 12px;
    background-color: #fcfcfc;
  }

  &__resend-text {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1rem;
  }

  &__resend-button {
    color: $light-blue;
    text-decoration: underline;

    &:hover {
      color: $dark-blue;
    }
  }

  &__yubico-container {
    margin-bottom: 30px;
    box-shadow: 0 0 1px 1px #eee;
    padding: 20px;
    border-radius: 12px;
    background-color: #fcfcfc;
  }

  &__error-message {
    font-family: "Roboto-Medium", sans-serif;
    font-size: 1.5rem;
    color: $primary-red;
    text-align: center;
    margin-bottom: 24px;
  }

  &__otp-container {
    margin-bottom: 40px;
    box-shadow: 0 0 1px 1px #eee;
    padding: 20px;
    border-radius: 12px;
    background-color: #fcfcfc;
  }

  &__form-error {
    color: $primary-red;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1rem;
    margin-left: 15px;
    margin-top: 10px;
  }
}

.yubi-key-form {
  &__submit {
    margin: 0 auto;
  }

  &__subtitle {
    font-family: "Raleway-Bold", sans-serif;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 24px;
  }

  &__list-item {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1.125rem;
    margin-bottom: 24px;
    margin-left: 19px;
  }

  &__list-item-text {
    padding-left: 8px;
  }

  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;

    @include below-breakpoint(xs) {
      flex-direction: column;
    }
  }

  &__passcode {
    @include above-breakpoint(xs) {
      margin-right: 24px;
      flex-basis: 62%;
    }

    @include below-breakpoint(xs) {
      margin-bottom: 24px;
      flex-basis: 100%;
    }
  }

  &__submit {
    @include button-red($padding: 20px 48px);
  }

  &__link {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1.125rem;
    color: $light-dark-grey;

    &:hover {
      color: $light-red;
    }

    @include below-breakpoint(xs) {
      align-self: center;
    }
  }

  &__switch-container {
    display: flex;
    flex-direction: row;

    @include below-breakpoint(xs) {
      margin-bottom: 24px;
    }
  }

  &__switch {
    margin-right: 12px;
  }

  &__switch-label {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1.125rem;
  }

  &__error-message {
    color: $primary-red;
    text-align: center;
    margin-bottom: 24px;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1.5rem;
  }
}

.confirmation {
  &__subtitle {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1.5rem;
    text-align: center;
  }
  &__email {
    font-family: "Raleway-Bold", sans-serif;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 68px;
  }
  &__input {
    margin-bottom: 15px;
    background-color: #fff;
  }
}
