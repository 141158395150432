@import 'src/assets/styles/index.scss';
@import 'src/assets/styles/mixins/container.scss';
@import 'src/assets/styles/mixins/media.scss';

.plans-screen-container {
  margin-bottom: 0px;
}

.plans-screen {
  @include content-container();

  &__oversea {
    color: #bbb;
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
}

.plans-screen__nav-back {
}

.plans-screen__header {
  font-family: 'Raleway-Bold', sans-serif;
  font-size: 2.6rem;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 10px;

  span {
    color: #d75f5c;
  }

  @include below-breakpoint(xs) {
    font-size: 2rem;
    margin-bottom: 48px;
  }
}

.plans-screen__sub {
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 1.8rem;
  color: #051431;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 10px;
}

.plans-screen__sub2 {
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 1.3rem;
  color: #051431;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 40px;
}

.plans-screen__cards-container {
  margin-bottom: 0;
}

.plans-screen__video {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  iframe {
    width: 600px;
    height: 350px;
    max-width: 100%;
  }
}
