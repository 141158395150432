@import 'src/assets/styles/mixins/button.scss';

.mpme--container {
  margin: 20px 0;
}

.mpme--button {
  @include button-red();
  margin: 20px auto 0;
}

.mpme--spinner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);

  &.spinner-wrapper {
    margin: 0;
  }

  .lds-ring {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }
}
