@import 'src/assets/styles/index.scss';

.search {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1.125rem;
  position: relative;
}

.search__icon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  pointer-events: none;
  user-select: none;
}

.search__input {
  width: 100%;
  padding: 12px 12px 12px 44px;
  border: 1px solid $input-border;
  border-radius: 10px;
  cursor: default;
  animation: open 0.3s ease-out forwards;

  &::-webkit-search-cancel-button {
    appearance: none;
    width: 16px;
    height: 16px;
    background-image: url('../../../../assets/images/icons/cross.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:focus {
    border-color: $primary-dark-blue;
  }

  &:disabled {
    background-color: $light-grey;
  }
}

.search__input.closed {
  width: 0;
  cursor: auto;
  animation: close 0.3s ease-in;
}

.search__erase-button {
  position: absolute;
  right: 21px;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes open {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes close {
  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}