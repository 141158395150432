.hwl-products--banner {
  width: 100%;
  background-color: #051431;
  overflow: hidden;
  padding: 30px 30px 40px;
  position: relative;
  border-radius: 12px;

  &--wrapper {
    position: relative;
    z-index: 3;
  }

  &--title {
    font-size: 40px;
    font-family: Raleway-Bold, "sans-serif";
    margin-bottom: 20px;
    color: #fff;
  }

  &--subtitle {
    font-size: 16px;
    color: #fff;
    font-family: "Roboto-Regular", "sans-serif";
    margin-bottom: 20px;

    .bold {
      font-family: "Roboto-Bold", "sans-serif";
    }
  }

  &--background {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    height: 100%;
  }

  &::after {
    @media (max-width: 850px) {
      content: '';
      background: linear-gradient(90.17deg, rgba(5, 20, 49, 0.568) 54.54%, rgba(5, 20, 49, 0) 93.72%);
      position: absolute;
      left: 0;
      top: 0;
      width:  100%;
      height: 100%;
    }
  }
}
