@font-face{
    font-family: "Raleway-Black";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/Raleway-Black.ttf")
}

@font-face{
    font-family: "Raleway-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Raleway-Bold.ttf")
}

@font-face{
    font-family: "Raleway-Extra-Bold";
    font-style: normal;
    font-weight: 800;
    src: url("../fonts/Raleway-ExtraBold.ttf")
}

@font-face{
    font-family: "Raleway-Medium";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/Raleway-Medium.ttf")
}

@font-face{
    font-family: "Roboto-Black";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/Roboto-Black.ttf")
}

@font-face{
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Roboto-Regular.ttf")
}

@font-face{
    font-family: "Roboto-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Roboto-Bold.ttf")
}

@font-face{
    font-family: "Roboto-Medium";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/Roboto-Medium.ttf")
}

@font-face{
    font-family: "Lato-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Lato-Bold.ttf")
}

@font-face{
    font-family: "Lato-Black";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/Lato-Black.ttf")
}

@font-face{
    font-family: "Lato-Extra-Bold";
    font-style: normal;
    font-weight: 800;
    src: url("../fonts/Lato-ExtraBold.ttf")
}

@font-face{
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Lato-Regular.ttf")
}

@font-face{
    font-family: "Raleway-Semi-Bold";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/Raleway-SemiBold.ttf")
}
