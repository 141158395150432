@import 'src/assets/styles/index.scss';

.activate-card-content {
  @include content-container-shadow();
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  @media (max-width: 600px) {
    margin: auto;
  }
  @media (max-width: 400px) {
    max-width: 327px;
  }
}

.activate-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: $basket-item-footer;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 4px 8px 0 #00000040;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
  }
  @media (max-width: 400px) {
    max-width: 327px;
  }
}

.card-sim__title {
  font-family: 'Raleway-Bold', sans-serif;
  font-size: calc(18px + (24 - 18) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(24px + (32 - 24) * ((100vw - 375px) / (1920 - 375)));
  font-weight: 700;
  color: $label-reverse;
  margin-bottom: 24px;
}

.card-sim__all-price {
  display: flex;
  align-items: baseline;
  margin-bottom: 24px;
}

.card-sim__price {
  font-family: 'Raleway-Bold', sans-serif;
  font-size: calc(32px + (56 - 32) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(40px + (64 - 40) * ((100vw - 375px) / (1920 - 375)));
  letter-spacing: 1px;
  margin-bottom: 8px;
}

.card-sim__split-prise {
  @include text-card();
  margin-left: 8px;
}

.card-sim__description {
  margin-bottom: 24px;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(24px + (32 - 24) * ((100vw - 375px) / (1920 - 375)));
}

.card-sim__list {
  margin-bottom: 24px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(24px + (32 - 24) * ((100vw - 375px) / (1920 - 375)));

  @include below-breakpoint(lg) {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  @include below-breakpoint(xs) {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.card-sim__list-item {
  margin-bottom: 12px;

  &:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    background: $primary-red;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
  }
}

.card-sim__type {
  @include header-two();
  font-size: calc(18px + (20 - 18) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(24px + (32 - 24) * ((100vw - 375px) / (1920 - 375)));
  font-weight: 700;
  margin-top: 24px;
}

.sim-activate {
  @include button-red();
  margin-left: 20px;
}

.activate-card__status {
  font-family: 'Raleway-Bold', sans-serif;
  font-size: calc(18px + (24 - 18) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(24px + (32 - 24) * ((100vw - 375px) / (1920 - 375)));
  font-weight: 700;
  color: $primary-red;
  text-transform: capitalize;
  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
  }
}

.port-status {
  text-align: center;
  padding: 7px 10px;
  background-color: #fff;
  border-radius: 6px;
  font-family: 'Raleway', sans-serif;
  font-size: calc(10px + (24 - 18) * ((100vw - 375px) / (1920 - 375)));

  small {
    font-size: 80%;
  }

  &--button {
    @include button-red($padding: 10px 20px);
    margin-top: 10px;
    width: 100%;
  }
}