.pw-banner {
  height: 600px;
  background-color: #F5F5F2;
  display: flex;
  padding: 25px 50px;
  grid-gap: 50px;

  &--left {
    flex: 1 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &--right {
    flex: 1 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  &--content-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &--content {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }

  &--title {
    font-family: Raleway-Bold, "sans-serif";
    font-size: 40px;
    color: #131313;

    span {
      color: rgba(215, 95, 92, 1);
    }
  }

  &--subtitle {
    font-family: Roboto-Regular, "sans-serif";
    font-size: 20px;
  }

  &--button {
    padding: 20px 45px;
    color: #fff;
    border-radius: 40px;
    background-color: #D75F5C;
    width: auto;
    font-family: Roboto-Black, "sans-serif";
    font-size: 16px;
  }

  &--recommend {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    flex-wrap: wrap;

    span {
      font-family: Roboto-Black, "sans-serif";
      font-size: 16px;
      color: #051431;
    }
  }

  &--rec-image {
    height: 30px;
    width: auto;
  }

  &--ios-screen {
    height: auto;
    width: auto;
    max-width: 100%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    height: auto;

    &--left, &--right {
      flex: 1 100%;
      height: auto;
    }

    &--right {
      flex-direction: row;
      justify-content: center;
    }

    &--button {
      margin-bottom: 40px;
    }

    &--ios-screen {
      height: 280px;
      width: auto;
    }
  }

  @media (max-width: 768px) {
    &--title {
      font-size: 28px;
    }

    &--subtitle {
      font-size: 16px;
    }
  }
}
