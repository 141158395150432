@import 'src/assets/styles/index.scss';

.account-tariff-info-transfer-device__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.account-tariff-info-transfer-device__title {
  font-family: 'Raleway-Bold';
  font-size: calc(18px + (24 - 18) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(24px + (32 - 24) * ((100vw - 375px) / (1920 - 375)));
  color: $label-reverse;
}

.account-tariff-info-transfer-device__code-wrapper {
  margin-top: 30px;
}

.account-tariff-info-transfer-device__link-generate {
  @include button-red();
  font-family: 'Raleway-Medium', sans-serif;
  margin-top: 30px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}
