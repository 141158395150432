.old-customer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &--title {
    font-family: Raleway-Bold, "sans-serif";
    font-size: 24px;
    color: #051431;
    margin-bottom: 30px;
  }

  &--purchases {
    width: 100%;
  }

  &--purchase {
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 0 1px 1px #eee;
    width: 100%;
    margin-bottom: 20px;

    &--detail {
      display: flex;
      height: 100%;
      background-color: #fff;
      border-radius: 6px;
      align-items: center;
      width: 100%;
      box-shadow: 0 0 1px 1px #eee;
      margin-bottom: 10px;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        width: 30%;
        height: 100%;
        background-color: #eee;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--label {
        font-family: Raleway-Bold, sans-serif;
        font-size: 14px;
        color: #000;
        flex: 0 30%;
        height: 100%;
        padding: 10px;
        z-index: 3;
      }

      &--value {
        font-family: Raleway-Regular, sans-serif;
        font-size: 16px;
        color: #051431;
        flex: 0 70%;
        padding: 10px;
        z-index: 3;
      }
    }
  }
}
