@import 'src/assets/styles/index.scss';
@import 'src/assets/styles/mixins/media.scss';

.popup-portal {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparentize($label-reverse, 0.4);
}

.popup {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  background-color: $label;
  border-radius: 10px;
  width: fit-content;
  transition: max-height 0.4s linear;
  overflow: hidden;

  @include below-breakpoint(sm) {
    width: 95%;
  }
}

.popup__close-button-container {
  text-align: end;
}

.popup__content {
  max-height: 85vh;
  overflow-y: auto;
}
