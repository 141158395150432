.carousel-container {
  padding-top: 25px !important;
}

.carousel-container .carouselCard {
  display: block !important;
  padding: 0 15px;
}

.carousel-container .carouselCardOdd {
  display: block !important;
  padding: 40px 15px 0 15px;
}

a.carouselCard:hover {
  text-decoration: none;
}

.carousel-card-banner {
  width: 100%;
}

.carousel-card-banner img {
  max-width: 500px;
  border-radius: 10px;
  width: 100%;
}

.carousel-card-info {
  font-family: 'Roboto-Regular';
  width: 100%;
  text-align: center;
}

.carousel-card-title h2 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}

.carousel-card-excerpt p {
  font-family: 'Roboto-Regular';
  font-size: 20px;
  line-height: 20px;
  margin-top: 16px;
}

.carousel-card-date {
  font-size: 16px;
  line-height: 16px;
  margin-top: 16px;
}

.slick-dots {
  position: relative !important;
  padding: 15px !important;
}

.slick-dots li button {
  font-size: 0;
  display: none;
}

.slick-next {
  right: -10px !important;
}

.slick-prev {
  left: -10px !important;
}
.margin-auto {
  margin: auto;
}

.instagram-section {
  background-color: #ebdfcf;
  // padding-top: 20px;
  // padding-bottom: 30px;
  padding: calc(48px + (125 - 48) * ((100vw - 375px) / (1920 - 375))) 20px 30px;
}

.section-banner__title {
  font-family: 'Raleway-Extra-Bold';
  font-size: calc(32px + (56 - 32) * ((100vw - 375px) / (1920 - 375)));
}