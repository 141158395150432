.hwl-dropdown {
  width: 100%;
  height: 100%;
  position: relative;

  &--title {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgba(236, 223, 206, 1);
    font-size: 16px;
    font-family: Roboto-Regular, sans-serif;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    transition: all .3s;
  }

  &--opener {
    width: 20px;
    height: auto;
    transition: transform .3s;
  }

  &--list {
    width: 100%;
    position: absolute;
    max-height: 250px;
    top: 50px;
    left: 0;
    z-index: 3;
    background-color: #fff;
    overflow-y: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    &--item {
      padding: 10px 15px;
      background-color: #fff;
      transition: background-color .3s;
      cursor: pointer;
      margin: 2px 0;
      font-family: Roboto-Regular, sans-serif;
      font-size: 16px;

      &:hover {
        background-color: rgba(236, 223, 206, .3);
      }
    }
  }

  &.opened {
    .hwl-dropdown {
      &--title {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &--opener {
        transform: rotate(180deg);
      }
    }
  }
}
