@import '../../../assets/styles/index.scss';

.modal-header {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  transition: transform 0.5s;
  transform: translateX(-100%);
}

.open {
  transform: translateX(0%);
  z-index: 3;
}

.modal-header-content {
  position: absolute;
  z-index: 5;
  left: 0;
  width: 80%;
  background-color: $label;
  height: 100vh;

  .hpb-button {
    display: flex;
  }
}

.background-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  bottom: 0;
  z-index: 5;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  opacity: 0;
  transition: opacity 0.2s;
}
.view {
  opacity: 1;
  pointer-events: painted;
  z-index: 3;
}

.modal-header-nav {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 24px;
  padding-bottom: 0;
  width: 100%;
  border-top: 2px solid $grey-line;
  max-width: 100%;

  .dropdown {
    width: 100%;
    .dropdown__placeholder {
      display: flex;
      width: 100%;
      padding: 15px 15px;
      margin-bottom: 10px;
      background-color: #eee;
      border-radius: 12px;
      font-size: 16px;
      max-width: 100%;
      justify-content: space-between;
    }

    .dropdown__options-list {
      left: 0;
      top: 55px;
      width: 100%;
    }
  }
}

.modal-header-up {
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: space-between;
  background-color: $label;
  color: $label-reverse;
  padding: 0 12px;
  height: auto;
  width: 100%;

  .up-header_item {
    color: $label-reverse;
    background-color: #eee;
    margin-right: 10px;
    border-radius: 20px;
    padding: 6px 20px;
    transition: color .3s, background-color .3s;

    &.active, &:hover {
      font-weight: initial;
      background-color: $primary-red;
      color: #fff;
    }
  }
}

.modal-user-link {
  margin: 28px auto;
  display: flex;
  align-items: center;
  padding: 0 24px;

  .header-account-dropdown .dropdown__placeholder {
    display: flex;
  }
}

.login-link__image-item_mobile-modal {
  width: 18px;
  height: 19px;
  margin-right: 10px;
}

.login-link__image-item_mobile-modal__door {
  width: 20px;
  height: 19px;
  margin-left: 10px;
}

.placeholder-menu-mobile {
  display: none;
  @media (max-width: 1200px) {
    display: block;
    overflow: visible;
    margin-left: 7px;
  }
}

.header-link{
  color: $primary-dark-blue;
}

.modal-header-plan-button {
  padding: 0 24px;
  margin-bottom: 25px;
}
