@import '../../../../assets/styles/index.scss';
@import '../../../../assets/styles/mixins/button.scss';

.present-main-content {
  position: relative;
  display: flex;
  align-items: center;
  background-color: $primary-dark-blue;
}

.gradient-mobile {
  @media (max-width: 800px) {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
  }
}

.present-main-content__information {
  width: 42%;

  @media (max-width: 500px) {
    width: 70%;
  }
}

.present-main-content__information_block {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0 30px;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 65px 20px;
  }
}

.present-main__image {
  width: 58%;
  padding-bottom: 38%;
  overflow: hidden;
  position: relative;
  @media (max-width: 1000px) {
    padding-bottom: 60%;
  }
  @media (max-width: 800px) {
    padding-bottom: 80%;
  }
  @media (max-width: 500px) {
    padding-bottom: 93%;
    width: 30%;
  }
}

.present-main__image-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: 800px) {
    display: none;
  }
  @media (max-width: 1600px) {
    width: 144%;
  }
  @media (max-width: 1000px) {
    width: 183%;
  }
}

.present-main__image-item_mini {
  width: 100%;
  display: none;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 800px) {
    display: block;
  }
  @media (max-width: 500px) {
    width: 151%;
  }
}

.main-closer-triangle {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 100vh solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 120px solid #051431;
  }

  @media (max-width: 768px) {
    display: block;
  }
}

.pmc-hide-images {
  .present-main__image-item {
    display: none;
  }

  .main-closer-triangle {
    display: none;
  }
}

.linxdot-plan-image {
  .present-main__image-item {
    display: block;
    @media (max-width: 800px) {
      display: none;
    }
  }
  .banner-video-container {
    display: none!important;
  }
  .main-closer-triangle {
    display: block!important;
  }
}
