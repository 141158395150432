@import 'src/assets/styles/index.scss';
@import 'src/assets/styles/mixins/text';
@import 'src/assets/styles/mixins/heading';
@import 'src/assets/styles/mixins/button.scss';
@import 'src/assets/styles/mixins/container.scss';

.new-basket-item {
  display: flex;
  flex-direction: column;
  background-color: $label;
  width: 100%;
  color: $primary-dark-blue;
  border: 1px solid rgba(5, 20, 49, .2);
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  padding: 20px 20px;
  transition: background-color .3s;
  margin-bottom: 20px;

  &__row {
    display: flex;
    flex-direction: row;

    &__left {
      width: calc(100% - 400px);
    }

    &__right {
      width: 400px;
    }
  }

  &__title {
    color: #051431;
    font-size: 45px;
    font-family: Raleway-Medium, sans-serif;

    b {
      font-family: Raleway-Bold, "sans-serif";
    }

    strong {
      font-family: Raleway-Extra-Bold, sans-serif;
    }
  }

  &__quantity {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__changer {
      height: 45px;
      padding: 0 30px;
      border-radius: 25px;
      background-color: $primary-red;
      font-family: Raleway-Bold, "sans-serif";
      font-size: 22px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      &__text {
        margin-right: 15px;
      }

      &__icon {
        margin-left: 15px;
        color: #fff;
        cursor: pointer;
        transition: transform .3s;

        &:hover {
          transform: scale(1.1);
        }
      }

      &__count {
        font-family: Lato-Bold, sans-serif;
        margin-left: 15px;
        font-size: 30px;
      }
    }

    &__trash {
      margin-left: 20px;
      &__icon {
        cursor: pointer;
        transition: transform .3s;
        width: 32px;
        height: auto;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &__collapser {
      transition: transform .3s;
      transform: rotate(270deg);
      margin-left: 20px;
      cursor: pointer;

      path {
        stroke: #000;
      }

      &.collapsed {
        transform: rotate(90deg);
      }
    }
  }

  &__data_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
  }

  &__data {
    width: 180px;
    height: 180px;
    border-radius: 100%;
    background-color: #051431;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;

    &__text {
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 3;
      text-align: center;
      margin-top: -20px;

      b {
        font-family: Lato-Black, "sans-serif";
        font-size: 70px;
        color: #fff;

        &.new-basket-item__data__text__infinite {
          font-family: Lato-Regular, "sans-serif";
          font-size: 120px;
          line-height: 80px;
        }

        &.new-basket-item__data__text__small {
          font-size: 30px;
        }

        small {
          font-size: 20px;
        }
      }

      &.small {
        b {
          font-size: 50px;
        }
      }

      span {
        font-family: Raleway-Medium, sans-serif;
        font-size: 15px;
        color: #fff;
      }
    }

    img {
      position: absolute;
      height: 85px;
      width:  auto;
      bottom: 8px;
      left: 6px;
    }
  }

  &__slogan {
    margin-top: 15px;
    font-family: Raleway-Medium, sans-serif;
    font-size: 20px;
    color: #051431;
  }

  &__price {
    margin-top: 10px;
    position: relative;

    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__title {
      font-family: Raleway-Bold, "sans-serif";
      font-size: 22px;
      color: #051431;
      margin-top: 30px;
    }

    &-unit {
      font-family: 'Raleway-Extra-Bold', sans-serif;
      font-style: italic;
      font-size: 46px;
      position: absolute;
      left: 0;
      top: 40px;
      color: #051431;
    }

    &-cost {
      font-family: 'Lato-Extra-Bold', sans-serif;
      font-size: 120px;
      color: $primary-red;
      margin-left: 30px;
    }

    &-monthly {
      margin-left: 10px;
      &--text {
        font-family: 'Raleway-Medium', sans-serif;
        font-size: 20px;
        color: #051431;
      }

      &--pill {
        font-family: 'Raleway-Extra-Bold', sans-serif;
        font-size: 16px;
        height: 32px;
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary-red;
        border-radius: 16px;
        margin-top: 5px;
        color: #fff;
      }
    }

    &-min-month {
      font-family: Raleway-Medium, sans-serif;
      font-size: 16px;
      color: #051431;
    }
  }

  &__price.small {
    .new-basket-item__price {
      &-unit {
        font-size: 28px;
        top: 3px;
      }
      &-cost {
        font-size: 56px;
        margin-left: 20px;
      }
      &-monthly {
        &--text {
          font-size: 18px;
        }
      }
    }
  }

  &__props {
    margin-top: 30px;

    &__title {
      font-family: Raleway-Bold, "sans-serif";
      font-size: 20px;
      margin-bottom: 15px;
      color: #051431;
    }

    &__plus {
      font-family: Raleway-Bold, "sans-serif";
      font-size: 20px;
      margin-bottom: 10px;
      font-style: italic;
      color: #051431;
    }

    &__item {
      margin-bottom: 10px;
      display: flex;
      transition: opacity .3s;
      opacity: 1;

      &__icon {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #051431;
        position: relative;
        margin-right: 5px;
        margin-top: 1px;

        img {
          position: absolute;
          width: 10px;
          height: 15px;
          top: 3px;
          left: 0px;
        }

        img.hover {
          display: none;
        }
      }

      &__text {
        font-family: Raleway-Medium, sans-serif;
        font-size: 16px;
        color: #051431;

        span {
          font-family: Raleway-Bold, "sans-serif";
          color: $primary-red;
        }
      }
    }

    &__asterisk {
      font-family: Raleway-Regular, sans-serif;
      font-size: 12px;
      color: #051431;
      position: relative;
      padding-left: 10px;

      b {
        font-family: Raleway-Extra-Bold, sans-serif;
        font-size: 24px;
        position: absolute;
        left: 0;
        top: -5px;
      }
    }

    &__highlight {
      font-family: Raleway-Regular, sans-serif;
      font-size: 14px;
      color: $primary-red;
      margin-bottom: 10px;
    }
  }

  &__flex {
    flex: 1;
  }

  &__type {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    &__title {
      font-family: Raleway-Bold, "sans-serif";
      font-size: 22px;
      color: #051431;
      margin-bottom: 10px;
    }

    &__desc {
      font-family: Raleway-Medium, sans-serif;
      font-size: 18px;
      color: #051431;
      margin-bottom: 10px;
    }

    &__switch {
      &__text {
        .switch__label {
          font-family: Raleway-Medium, sans-serif;
          font-size: 22px;
          color: #051431;
        }
      }
    }
  }

  @media (max-width: 1196px) {
    &__title {
      font-size: 30px;
    }

    &__slogan {
      font-size: 16px;
    }

    &__row {
      &__left {
        width: calc(100% - 300px);
      }

      &__right {
        width: 300px;
      }
    }

    &__props {
      &__title {
        font-size: 16px;
      }
      &__item {
        &__icon {
          width: 13px;
          height: 13px;
          img {
            width: 9px;
            height: 12px;
            top: 3px;
            left: 0px;
          }
        }
        &__text {
          font-size: 14px;
        }
      }
      &__plus {
        font-size: 16px;
      }
      &__asterisk {
        font-size: 10px;
      }
    }

    &__quantity {
      &__changer {
        height: 35px;
        padding: 0 15px;
        border-radius: 35px;
        font-size: 18px;

        &__text {
          margin-right: 0;
        }

        &__count {
          font-size: 25px;
        }
      }
      &__trash {
        &__icon {
          width: 26px;
        }
      }
    }

    &__data {
      width: 130px;
      height: 130px;

      &__text {
        bottom: 5px;
        width: 100px;

        b {
          font-size: 50px;

          &.new-basket-item__data__text__infinite {
            font-size: 90px;
            line-height: 60px;
          }

          &.new-basket-item__data__text__small {
            font-size: 20px;
          }

          small {
            font-size: 16px;
          }
        }

        &.small {
          b {
            font-size: 35px;
          }
        }

        span {
          font-size: 10px;
        }
      }
    }

    &__type {
      &__title {
        font-size: 18px;
      }
      &__desc {
        font-size: 14px;
      }
      &__switch {
        &__text {
          .switch__label {
            font-size: 18px;
          }
        }
      }
    }

    &__price {
      &__title {
        font-size: 18px;
      }
      &-unit {
        font-size: 36px;
        top: 30px;
      }
      &-cost {
        font-size: 100px;
        margin-left: 20px;
      }
      &-monthly {
        &--text {
          font-size: 15px;
        }

        &--pill {
          height: 28px;
          font-size: 13px;
        }
      }
      &-min-month {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 680px) {
    &__title {
      font-size: 28px;
    }

    &__price {
      &-unit {
        top: 65px;
      }
      &-cost {
        margin-left: 28px;
      }
    }

    &__row {
      &__left {
        width: calc(100% - 200px);
      }

      &__right {
        width: 200px;
      }
    }

    &__data {
      width: 80px;
      height: 80px;

      &__text {
        bottom: 0;
        width: 100px;
        margin-top: -13px;

        b {
          font-size: 35px;

          &.new-basket-item__data__text__infinite {
            font-size: 60px;
            line-height: 40px;
          }

          &.new-basket-item__data__text__small {
            font-size: 14px;
          }

          small {
            font-size: 12px;
          }
        }

        &.small {
          b {
            font-size: 25px;
          }
        }

        span {
          font-size: 8px;
        }
      }

      img {
        height: 50px;
        width: auto;
        bottom: 3px;
        left: 0px;
      }
    }

    &__quantity {
      &__changer {
        height: 30px;
        padding: 0 10px;
        border-radius: 30px;
        font-size: 15px;

        &__text {
          margin-right: 0;
        }

        &__count {
          font-size: 20px;
          margin-left: 10px;
        }

        &__icon {
          margin-left: 10px;
        }
      }
      &__trash {
        margin-left: 15px;
        &__icon {
          width: 25px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    &__row {
      flex-direction: column;

      &__left {
        width: 100%;
        margin-bottom: 10px;
      }

      &__right {
        width: 100%;
      }
    }
    &__data_container {
      justify-content: center;
    }
  }
}
