.mcp--popup {
  width: 700px;
  max-width: 100%;

  .popup__content {
    overflow: hidden;
    min-height: 200px;
  }
}

.mcp--title {
  width: 100%;
  text-align: center;
  font-family: "Raleway-Bold", sans-serif;
  font-size: 26px;
  position: absolute;
  top: 20px;
  z-index: -1;
  left:0;
}

.mcp--container {
  .spinner-wrapper {
    margin: 30px 0 20px;
    text-align: center;
    position: absolute;
    left: 0;
    top: -15px;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .1);
  }

  .payment__form {
    width: 100%;
    margin-top: 30px;
  }

  .payment-form__card-container {
    max-width: initial;
    width: 100%;
  }

  .payment-form__card-side {
    max-width: initial;
    width: 100%;
    padding: 20px;
  }

  .payment-form__cvv-background {
    height: 146px;
    bottom: -85px;
    top: auto;
    right: auto;
    left: 0;
  }

  .payment-form__field-container.cvv {
    position: absolute;
    right: auto;
    left: 20px;
    bottom: -70px;
  }

  .payment-form__card__trustbadge {
    position: absolute;
    width: 300px;
    right: 5px;
    bottom: -73px;
  }

  .payment-form__button-pay {
    display: block!important;
    margin-top: 120px;
  }

  .payment-form__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mpme--container {
    margin-top: 30px;
    margin-bottom: 0;
  }
}
