.pw-coverage {
  width: 100%;
  height: 450px;
  background-color: #697895;
  position: relative;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  margin-bottom: 100px;

  &--content {
    width: 50%;
  }

  &--content-title {
    font-family: Raleway-Bold, "sans-serif";
    font-size: 34px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &--content-subtitle {
    font-family: Roboto-Regular, "sans-serif";
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
  }

  &--content-button {
    padding: 20px 45px;
    color: #fff;
    border-radius: 40px;
    background-color: #D75F5C;
    width: auto;
    font-family: Roboto-Black, "sans-serif";
    font-size: 16px;
  }

  &--banner {
    width: auto;
    max-width: 50%;
    max-height: 70%;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 40px 40px;
    margin-bottom: 40px;

    &--content {
      width: 100%;
    }

    &--banner {
      display: none;
    }

    &--content-title {
      font-size: 26px;
    }

    &--content-subtitle {
      font-size: 16px;
    }
  }
}
