@import 'src/assets/styles/index.scss';

.account-edit-otp-successful {
  text-align: center;

  &__title {
    font-family: 'Raleway-Bold', 'sans-serif';
    font-size: calc(20px + (28 - 20) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(28px + (36 - 28) * ((100vw - 375px) / (1920 - 375)));
    color: $primary-dark-blue;

    margin-top: calc(8px + (24 - 8) * ((100vw - 375px) / (1920 - 375)));
  }

  &__btn {
    @include button-red();

    margin: 24px auto 0;
  }
}
