.hwl-accordion {
  width: 100%;

  &--collapser {
    width: 25px;
    height: auto;
    transition: transform .3s;
    transform: rotate(-180deg);
  }

  &--title {
    height: 50px;
    width: 100%;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 18px;
    color: #051431;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #051431;
    margin-bottom: 15px;
    padding: 0 3px;

    &.collapsed {
      border-bottom: 1px solid rgba(5, 20, 49, .2);

      .hwl-accordion--collapser {
        transform: rotate(0deg);
      }
    }
  }

  &--content {
    width: 100%;
  }
}
