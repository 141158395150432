
.sidebar-social-share {
    display: flex;
    flex-direction: row;
    max-width: 264px;
    margin-bottom: 10px;
  }
  
  .sidebar-social-share i {
    box-sizing: border-box;
    margin: 16px 24px 0px 0px;
    min-width: 0px;
    color: #252b37;
    font-size: 24px;
    width: 1em;
    height: 1em;
  }