@import '../../../../assets/styles/index.scss';
@import '../../../../assets/styles/mixins/button.scss';

.present-main-block {
  width: 100%;
  margin-bottom: calc(25px + (157 - 48) * ((100vw - 375px) / (1920 - 375)));
}

.present-main-content__information_text {
  font-family: 'Roboto-Regular';
  width: 100%;
  font-size: 35px;
  line-height: 45px;
  color: $label;

  b, strong {
    font-family: 'Roboto-Bold';
    color: #d75f5c;
    font-size: 38px;
  }

  span.small, small {
    font-size: 13px;
    line-height: 18px;
    display: inline-block;
    max-width: 500px;
  }

  @media (max-width: 1400px) {
    font-size: 35px;
    line-height: 45px;

    b, strong {
      font-size: 38px;
    }
  }

  @media (max-width: 1250px) {
    font-size: 35px;
    line-height: 45px;

    b, strong {
      font-size: 38px;
    }
  }

  @media (max-width: 570px) {
    font-size: 20px;
    line-height: 25px;

    b, strong {
      font-size: 23px;
    }
  }
}
.button {
  margin-top: calc(24px + (48 - 24) * ((100vw - 375px) / (1920 - 375)));
  @include button-red($padding: 18px 48px);
  text-transform: uppercase;
  @media (max-width: 800px) {
    margin: calc(24px + (48 - 24) * ((100vw - 375px) / (1920 - 375))) auto 0 auto;
  }
  @media (max-width: 400px) {
    margin: calc(24px + (48 - 24) * ((100vw - 375px) / (1920 - 375))) auto 0 auto;
    width: 100%;
  }
}

.present-main-technologies {
  padding: 0 calc(4px + (200 - 4) * ((100vw - 375px) / (1920 - 375)));
  margin: calc(33px + (90 - 33) * ((100vw - 375px) / (1920 - 375))) 0;
  font-family: 'Raleway-Bold';
  font-size: calc(18px + (24 - 18) * ((100vw - 375px) / (1920 - 375)));
  line-height: calc(24px + (32 - 24) * ((100vw - 375px) / (1920 - 375)));
}

.present-main-technologies_block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
}

.technologies_block-item {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 362px;
  padding: 0 20px;
  width: 33.333%;
  @media (max-width: 800px) {
    margin: 0 auto;
    width: 100%;
  }
}

.technologies_block-image {
  width: 66px;
  height: 66px;
  @media (max-width: 800px) {
    margin-top: 48px;
  }
}

.technologies_block-text {
  margin-top: 24px;
}

.present-main-protection {
  padding: 15px 30px;
  font-family: "Raleway-Bold", sans-serif;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  white-space: nowrap;
  flex-direction: column;

  @media (max-width: 1300px) {
    font-size: 35px;
  }
  @media (max-width: 1160px) {
    font-size: 30px;
  }
  @media (max-width: 1000px) {
    font-size: 20px;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    font-size: 18px;
    span {
      margin-bottom: 5px;
    }
  }
  @media (max-width: 400px) {
    font-size: 16px;
  }

  &__orange {
    color: #d75f5c;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }

  &__black {
    color: #09142f;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    height: 40px;
    width: auto;
    display: inline-block;
    margin: 0 5px;

    @media (max-width: 1300px) {
      height: 35px;
    }
    @media (max-width: 1160px) {
      height: 30px;
    }
    @media (max-width: 1000px) {
      height: 20px;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.present-main-video {
  display: none;
  margin: 15px 20px 0;
  iframe {
    width:100%;
    height: 300px;
  }

  @media (max-width: 768px) {
    display: block;
  }
}
