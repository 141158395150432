@import '../../../../assets/styles/index.scss';
@import '../../../../assets/styles/mixins/button.scss';

.hpb-container {
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-position-y: center;
  position: relative;

  &--inner-view {
    width: 70%;
    height: 100%;
    background: linear-gradient(90.17deg, rgba(5, 20, 49, 0.71) 38.57%, rgba(5, 20, 49, 0) 93.72%);
    padding: 0 calc(22px + (200 - 22) * ((100vw - 375px) / (1920 - 375)));
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--texts {
      max-width: 500px;
    }

    .hpb-text-1 {
      font-family: Raleway-Extra-Bold, sans-serif;
      font-size: 60px;
      color: #fff;

      span {
        color: $primary-red;
      }
    }

    .hpb-pills {
      margin-top: 25px;
      display: flex;

      .hpb-pill-item {
        padding: 0 15px;
        height: 40px;
        font-family: Raleway-Extra-Bold, sans-serif;
        font-size: 18px;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
      }
    }

    .hpb-text-2 {
      font-family: Raleway-Medium, sans-serif;
      font-size: 22px;
      margin-top: 25px;
      color: #fff;
      line-height: 30px;

      span {
        font-family: Raleway-Bold, sans-serif;
        color: $primary-red;
      }
    }

    .hpb-text-3 {
      font-family: Raleway-Medium, sans-serif;
      font-size: 22px;
      margin-top: 25px;
      color: #fff;

      span {
        font-family: Raleway-Bold, sans-serif;
        color: $primary-red;
      }

      sup {
        top: 0;
      }
    }

    .hpb-text-4 {
      font-family: Raleway-Regular, sans-serif;
      font-size: 13px;
      margin-top: 25px;
      color: #fff;
      position: relative;
      padding-left: 15px;

      span {
        font-family: Raleway-Extra-Bold, sans-serif;
        font-size: 26px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .hpb-button {
      font-family: Raleway-Extra-Bold, sans-serif;
      font-size: 25px;
      color: #fff;
      padding: 0 70px 0 40px;
      height: 60px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary-red;
      position: relative;
      margin-top: 25px;
      transition: transform .3s;

      &--icon {
        width: auto;
        height: 20px;
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        transition: right .3s;
      }

      &--vector {
        width: auto;
        position: absolute;
        left: 7px;
        bottom: -13px;
        height: 40px;
      }

      &:hover {
        transform: scale(1.1);

        .hpb-button--icon {
          right: 15px;
        }
      }
    }
  }

  @media (max-width: 980px) {
    background-position-x: left;

    &--inner-view {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 860px) {
    height: 500px;

    &--inner-view {
      background: rgba(0, 0, 0, .6);

      &--texts {
        max-width: 375px;
      }

      .hpb-text-1 {
        font-size: 40px;
      }

      .hpb-pills {
        margin-top: 20px;

        .hpb-pill-item {
          padding: 0 15px;
          height: 35px;
          font-size: 14px;
          border-radius: 20px;
          margin-right: 15px;
        }
      }

      .hpb-text-2 {
        font-size: 18px;
        margin-top: 20px;
        line-height: 22px;
      }

      .hpb-text-3 {
        font-size: 18px;
        margin-top: 20px;
      }

      .hpb-text-4 {
        font-size: 12px;
        margin-top: 20px;
        padding-left: 15px;

        span {
          font-size: 22px;
        }
      }

      .hpb-button {
        font-size: 20px;
        padding: 0 50px 0 30px;
        height: 50px;
        border-radius: 25px;
        margin-top: 20px;

        &--icon {
          height: 17px;
          right: 25px;
        }

        &--vector {
          left: 7px;
          bottom: -13px;
          height: 40px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    &--inner-view {
      padding: 0 20px;

      .hpb-pills {
        .hpb-pill-item {
          padding: 0 15px;
          height: 40px;
          border-radius: 20px;
          text-align: center;
        }
      }
    }
  }
}

.banner-video-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;

  .banner-video-title {
    font-family: 'Roboto-Bold';
    color: #d75f5c;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
  }

  .banner-video {
    width: 100%;
    height: calc(100% - 30px);
  }

  @media (max-width: 768px) {
    display: none;
  }
}
