@import 'src/assets/styles/index.scss';
@import 'src/assets/styles/mixins/text';
@import 'src/assets/styles/mixins/heading';
@import 'src/assets/styles/mixins/button.scss';
@import 'src/assets/styles/mixins/container.scss';

.plan-card {
  display: flex;
  flex-direction: column;
  background-color: $label;
  width: calc(320px + (362 - 320) * ((100vw - 375px) / (1920 - 375)));
  margin: 12px 12px 40px 12px;
  color: $primary-dark-blue;
  border: 1px solid rgba(5, 20, 49, .2);
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  padding: 0 20px;
  transition: background-color .3s;

  &__title {
    color: #051431;
    font-size: 30px;
    font-family: Raleway-Medium, sans-serif;
    margin-top: 40px;
    max-width: 175px;

    b {
      font-family: Raleway-Bold, "sans-serif";
    }

    strong {
      font-family: Raleway-Extra-Bold, sans-serif;
    }
  }

  &__data {
    position: absolute;
    width: 190px;
    height: 190px;
    right: -40px;
    top: -40px;
    border-radius: 100%;
    background-color: #051431;

    &__text {
      position: absolute;
      display: flex;
      flex-direction: column;
      left: 10px;
      bottom: 45px;
      width: 140px;
      z-index: 3;
      text-align: center;

      b {
        font-family: Lato-Black, "sans-serif";
        font-size: 70px;
        color: #fff;

        small {
          font-size: 20px;
        }
      }

      &.small {
        b {
          font-size: 50px;
        }
      }

      span {
        font-family: Raleway-Medium, sans-serif;
        font-size: 15px;
        color: #fff;
      }
    }

    img {
      position: absolute;
      height: 85px;
      width:  auto;
      bottom: 8px;
      left: 6px;
    }
  }

  &__slogan {
    margin-top: 15px;
    font-family: Raleway-Medium, sans-serif;
    font-size: 18px;
    color: #051431;
  }

  &__price {
    margin-top: 10px;
    position: relative;
    padding-left: 35px;

    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-unit {
      font-family: 'Raleway-Extra-Bold', sans-serif;
      font-style: italic;
      font-size: 46px;
      position: absolute;
      left: 0;
      top: 17px;
      color: #051431;
    }

    &-cost {
      font-family: 'Lato-Extra-Bold', sans-serif;
      font-size: 120px;
      color: $primary-red;
    }

    &-monthly {
      margin-left: 10px;
      &--text {
        font-family: 'Raleway-Medium', sans-serif;
        font-size: 20px;
        color: #051431;
      }

      &--pill {
        font-family: 'Raleway-Extra-Bold', sans-serif;
        font-size: 16px;
        height: 32px;
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary-red;
        border-radius: 16px;
        margin-top: 5px;
        color: #fff;
      }
    }

    &-min-month {
      font-family: Raleway-Medium, sans-serif;
      font-size: 16px;
      color: #051431;
    }
  }

  &__props {
    margin-top: 30px;

    &__title {
      font-family: Raleway-Bold, "sans-serif";
      font-size: 20px;
      margin-bottom: 15px;
      color: #051431;
    }

    &__plus {
      font-family: Raleway-Bold, "sans-serif";
      font-size: 20px;
      margin-bottom: 10px;
      font-style: italic;
      color: #051431;
    }

    &__item {
      margin-bottom: 10px;
      display: flex;
      transition: opacity .3s;
      opacity: 1;

      &__icon {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #051431;
        position: relative;
        margin-right: 5px;
        margin-top: 1px;

        img {
          position: absolute;
          width: 10px;
          height: 15px;
          top: 3px;
          left: 0px;
        }

        img.hover {
          display: none;
        }
      }

      &__text {
        font-family: Raleway-Medium, sans-serif;
        font-size: 16px;
        color: #051431;

        span {
          font-family: Raleway-Bold, "sans-serif";
          color: $primary-red;
        }
      }
    }

    &__asterisk {
      font-family: Raleway-Regular, sans-serif;
      font-size: 12px;
      color: #051431;
      position: relative;
      padding-left: 19px;

      b {
        font-family: Raleway-Extra-Bold, sans-serif;
        font-size: 40px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  &__buttons {
    margin: 30px 0 20px 0;

    &-button {
      width: 100%;
      height: 50px;
      cursor: pointer;
      font-family: Raleway-Bold, "sans-serif";
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 25px;
      transition: transform .3s;

      &:hover {
        transform: scale(1.05);
      }

      &.outline {
        border: 1px solid rgba(5, 20, 49, .2);
        color: #051431;
        margin-bottom: 15px;
      }

      &.primary {
        background-color: $primary-red;
        color: #fff;
      }
    }
  }

  &__flex {
    flex: 1;
  }

  &:hover, &.darken {
    background-color: $primary-red;
    border-color: $primary-red;

    .plan-card__title {
      color: #fff;
    }

    .plan-card__slogan {
      color: #fff;
    }

    .plan-card__price {
      &-unit {
        color: #fff;
      }

      &-cost {
        color: #fff;
      }

      &-monthly {
        &--text {
          color: #fff;
        }

        &--pill {
          background-color: #fff;
          color: #051431;
        }
      }

      &-min-month {
        color: #fff;
      }
    }

    .plan-card__props {
      &__title {
        color: #fff;
      }
      &__plus {
        color: #fff;
      }
      &__item {
        &__icon {
          background-color: #fff;

          img {
            display: none;
          }
          img.hover {
            display: block;
          }
        }

        &__text {
          color: #fff;

          span {
            color: #fff;
          }
        }
      }
      &__asterisk {
        color: #fff;
      }
    }

    .plan-card__buttons {
      &-button {
        &.outline {
          border-color: #fff;
          color: #fff;
        }

        &.primary {
          background-color: #fff;
          color: #051431;
        }
      }
    }
  }

  @media (max-width: 747px) {
    margin-bottom: 15px;

    &__title {
      margin-top: 20px;
      font-size: 18px;
    }

    &__slogan {
      margin-top: 10px;
      font-size: 10px;
    }

    &__data {
      width: 130px;
      height: 130px;
      right: -20px;
      top: -20px;

      &__text {
        bottom: 35px;
        width: 100px;

        b {
          font-size: 50px;

          small {
            font-size: 16px;
          }
        }

        &.small {
          b {
            font-size: 35px;
          }
        }

        span {
          font-size: 10px;
        }
      }
    }

    &__price {
      margin-top: 5px;
      padding-left: 25px;

      &-unit {
        font-size: 26px;
        top: 10px;
      }

      &-cost {
        font-size: 60px;
      }

      &-monthly {

        &--text {
          font-size: 12px;
        }

        &--pill {
          font-size: 10px;
          height: 16px;
          padding: 0 5px;
          border-radius: 8px;
          margin-top: 3px;
        }
      }

      &-min-month {
        font-size: 9px;
      }
    }

    &__props {
      margin-top: 10px;

      &__title {
        font-size: 14px;
        margin-bottom: 10px;
      }

      &__plus {
        font-size: 14px;
        margin-bottom: 7px;
      }

      &__item {
        margin-bottom: 7px;

        &__icon {
          width: 12px;
          height: 12px;
          margin-right: 5px;
          margin-top: 1px;

          img {
            width: 8px;
            height: 17px;
            top: 0px;
            left: 0px;
          }
        }

        &__text {
          font-size: 14px;
        }
      }

      &__asterisk {
        font-size: 10px;

        b {
          font-size: 35px;
        }
      }
    }

    &__buttons {
      margin-top: 10px;

      &-button {
        height: 40px;
        font-size: 16px;
        border-radius: 20px;

        &.outline {
          margin-bottom: 10px;
        }
      }
    }
  }
}
