@import 'src/assets/styles/index.scss';

.change-plan-payment {
  &--popup {
    width: 600px;
    max-width: 90%;

    .popup__content {
      overflow: hidden;
    }
  }

  &--title {
    font-family: "Raleway-Bold", sans-serif;
    position: absolute;
    left: 50%;
    top: 25px;
    transform: translateX(-50%);
    font-size: 20px;
    width: 100%;
    text-align: center;
  }

  &--container {
    margin-top: 20px;
  }

  &--button {
    @include button-red();
  }

  &--inner {
    width: 100%;
  }

  &--buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    grid-gap: 15px;
    margin-top: 20px;
  }

  &--price {
    font-family: "Raleway-Bold", sans-serif;
    font-size: 28px;
    color: #0a265c;
  }

  &--spinner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);

    &.spinner-wrapper {
      margin: 0;
    }

    .lds-ring {
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
}
