.hwl-products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;

  &--item {
    width: calc(33.33% - 10px);

    @media (max-width: 1500px) {
      width: calc(50% - 10px);
    }
    @media (max-width: 590px) {
      width: 100%;
    }
  }

  &--loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
  }
}
