.hwl-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
  height: 50px;
  width: 100%;

  &--query {
    width: calc(100% - 240px);
    position: relative;
    height: 100%;

    &--search-icon {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    &--search-input {
      width: 100%;
      height: 100%;
      padding-left: 40px;
      padding-right: 15px;
      border: 1px solid rgba(5, 20, 49, 0.2);
      border-radius: 10px;
      font-family: Roboto-Regular, sans-serif;
      font-size: 18px;
    }
  }

  &--filter-sort {
    height: 100%;
  }

  &--sort {
    width: 220px;
    height: 100%;
  }
}

.hwl-search.mobile {
  flex-direction: column;
  height: auto;

  .hwl-search {
    &--query {
      width: 100%;
      height: 50px;
      margin-bottom: 20px;
    }

    &--filter-sort {
      height: 50px;
      display: flex;
      align-items: center;
      grid-gap: 20px;
      width: 100%;
    }

    &--filter {
      flex: 1 50%;
      height: 100%;

      &--button {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ECDFCE;
        border-radius: 10px;
        padding: 5px 10px;
        font-size: 18px;
        color: #051431;
        font-family: "Roboto-Regular", sans-serif;

        img {
          width: 18px;
          height: 18px;
          margin-left: 10px;
        }
      }
    }

    &--sort {
      flex: 1 50%;
    }
  }

}


.hwl-search-filter-modal {
  opacity: 0;
  left: -101vw;
  top: 0;
  position: fixed;
  transition: opacity .3s, left .3s;
  background-color: rgba(0, 0, 0, .1);
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;

  &.active {
    left: 0;
    opacity: 1;
  }

  &--content {
    width: 70%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.13);
    padding: 24px;
    position: relative;

    @media (max-width: 400px) {
      width: 80%;
    }
  }

  &--close {
    cursor: pointer;
    width: 32px;
    height: auto;
    position: absolute;
    right: 24px;
    top: 24px;
    padding: 5px;
  }

  &--close-side {
    width: 30%;
    height: 100%;

    @media (max-width: 400px) {
      width: 20%;
    }
  }
}
