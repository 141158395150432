.hwd-similar-products {
  width: 100%;
  margin-bottom: 20px;

  &--item {
    width: 350px;
  }

  &--title {
    font-family: Raleway-Bold, "sans-serif";
    font-size: 50px;
    margin-bottom: 40px;

    @media (max-width: 760px) {
      font-size: 32px;
      margin-bottom: 20px;
    }
  }

  &--list {
    width: 100%;
    position: relative;
  }

  &--list-overflow-outer {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  &--list-overflow {
    position: relative;
    top: 0;
    left: 0;
    transition: left .3s;
    display: flex;
    grid-gap: 15px;
  }

  &--next, &--prev {
    cursor: pointer;
    width: 30px;
    height: 30px;
    top: 50%;
    position: absolute;
    z-index: 9;
  }

  &--next {
    transform: rotate(-90deg);
    right: -40px;
  }

  &--prev {
    transform: rotate(90deg);
    left: -40px
  }

  @media (max-width: 600px) {
    &--title {
      text-align: center;
    }
    &--list-overflow-outer {
      width: calc(100% - 80px);
    }
    &--next {
      right: 0;
    }

    &--prev {
      left: 0;
    }

    &--list {
      display: flex;
      justify-content: center;
    }
  }
}
