.hwl-price-slider-container {
  width: 100%;
}

.hwl-price-input {
  display: flex;
  grid-gap: 15px;
  height: 50px;

  &--item {
    width: 50%;
    height: 100%;

    input {
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      border: 1px solid #DCDCE4;
      border-radius: 10px;
      padding: 5px 10px;
      font-family: Roboto-Regular, sans-serif;
      font-size: 18px;
      color: #A5A5A5;
    }
  }
}

.hwl-price-slider {
  margin-top: 15px;
  height: 10px;

  &--thumb {
    width: 18px;
    height: 18px;
    background-color: #D75F5C;
    border-radius: 18px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 0;
  }

  &--track {
    background-color: rgba(236, 223, 206, 1);
    height: 10px;
    border-radius: 10px;
  }
}
