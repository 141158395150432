.vpn-block {
  display: flex;
  flex-direction: row;
  height: 400px;
  background-color: #051431;
  color: #fff;

  &--left {
    flex: .4;
    padding: 10px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 30px;
      font-family: "Raleway-Bold", sans-serif;
      text-align: center;
    }
  }
  &--right {
    flex: .6;
    background-size: cover;
    background-position: top center;
  }
  &--items {
    width: 100%;
  }
  &--item {
    margin-top: 26px;
    display: flex;
    align-items: center;

    img {
      width: 25px;
      margin-right: 10px;
    }

    span {
      font-family: "Raleway-Bold", sans-serif;
      font-size: 20px;
      color: #d75f5c;
    }
  }

  @media (max-width: 840px) {
    &--left {
      flex: .5;
    }
    &--right {
      flex: .5;
    }
  }

  @media (max-width: 600px) {
    &--left {
      padding-left: 20px;
      padding-right: 20px;
      h3 {
        font-size: 20px;
      }
    }
    &--item {
      margin-top: 20px;

      img {
        width: 20px;
      }

      span {
        font-size: 15px;
      }
    }
  }

  @media (max-width: 370px) {
    &--left {
      flex: .7;
    }
    &--right {
      flex: .3;
    }
  }
}
