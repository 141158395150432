@import 'src/assets/styles/index';
@import 'src/assets/styles/mixins/media';
@import 'src/assets/styles/mixins/button';

.reset-password-success {
  margin: 150px 0 325px 0;

  @include below-breakpoint(xs) {
    margin-top: 40px;
  }
}

.reset-password-success__header {
  font-family: "Raleway-Bold", sans-serif;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 48px;

  @include below-breakpoint(xs) {
    font-size: 2rem;
  }
}

.reset-password-success__button {
  @include button-red($padding: 20px 48px);
  display: block;
  margin: 0 auto;
}