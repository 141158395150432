@import '../../../assets/styles/index.scss';

.base-header {
  width: 100%;
  font-family: 'Roboto-Medium', sans-serif;
  line-height: 16px;
  color: $label-reverse;
  background-color: $label;
}

.header-container {
  padding: 15px calc(22px + (200 - 22) * ((100vw - 375px) / (1920 - 375)));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $label;
}

.header-container__sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  font-family: 'Roboto-Medium', sans-serif;
  line-height: 16px;
  color: $label-reverse;
  background-color: $label;
}

.header-logo {
  width: auto;
  height: 55px;
}

.header-burger {
  display: none;
  @media (max-width: 1200px) {
    display: block;
    cursor: pointer;
  }
}

.header-burger {
  width: 28px;
  height: 28px;
}

.base-header-up-header {
  background-color: $primary-red;
  width: 100%;
  height: 44px;
  transition: all 0.2s linear;
}

.base-header-up-header__height {
  background-color: $primary-red;
  width: 100%;
  pointer-events: none;
  height: 0;
  opacity: 0;
}

.hpb-button {
  font-family: Raleway-Extra-Bold, sans-serif;
  font-size: 14px;
  color: #fff;
  padding: 0 30px 0 15px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-red;
  position: relative;
  margin-top: 0;
  transition: transform .3s;

  &--icon {
    width: auto;
    height: 14px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: right .3s;
  }

  &--vector {
    width: auto;
    position: absolute;
    left: 7px;
    bottom: -9px;
    height: 20px;
  }

  &:hover {
    transform: scale(1.1);

    .hpb-button--icon {
      right: 5px;
    }
  }
}

.navigation-menu {
  .dropdown {
    width: auto;

    &__placeholder {
      font-family: Raleway-Medium, sans-serif;
      font-size: 14px;
    }
  }
}

@media (max-width: 1200px) {
  .header-logo-block {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;

    .header-logo {
      height: 40px;
    }
  }
  .header-container {
    .hpb-button {
      display: none;
    }
  }
  .header-link-bag {
    margin-right: 0;
  }
}
