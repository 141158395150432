.hwl-filters {

  &--title {
    font-size: 24px;
    font-family: Raleway-Bold, sans-serif;
    color: #051431FF;
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &--clear-all {
    font-size: 16px;
    font-family: Roboto-Black, sans-serif;
    color: #D75F5C;
    margin-left: 15px;
    margin-bottom: 3px;
    text-decoration: underline;
    cursor: pointer;

    @media (max-width: 600px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
