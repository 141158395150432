@import 'src/assets/styles/index';
@import 'src/assets/styles/mixins/media';
@import 'src/assets/styles/mixins/button';

.restore-password-form {
  max-width: 630px;
  width: 100%;
  margin: 0 auto 160px auto;
}

.restore-password-form__header {
  font-family: "Raleway-Bold", sans-serif;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 80px;

  @include below-breakpoint(xs) {
    font-size: 2rem;
    margin-bottom: 48px;
  }
}

.restore-password-form__title {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 48px;
  text-align: center;
}

.restore-password-form__email-input {
  margin: 0 auto 24px auto;
  max-width: 500px;
  width: 100%;
}

.restore-password-form__error-message {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 1.5rem;
  color: $primary-red;
  text-align: center;
  margin-bottom: 24px;
}

.restore-password-form__controls {
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  @include below-breakpoint(xs) {
    flex-direction: column;
  }
}

.restore-password-form__back-button {
  @include button-white-blue($padding: 20px 48px);
  flex-basis: 30%;
}

.restore-password-form__send-button {
  @include button-red($padding: 20px 48px);
  flex-basis: 40%;
}