.hwl-product-item {
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid rgba(5, 20, 49, 0.2);
  border-radius: 10px;
  transition: box-shadow .3s;
  padding: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.13);
  }

  &--top-tags {
    display: flex;
    width: 100%;
    height: 40px;
    grid-gap: 10px;
    margin-bottom: 15px;
  }

  &--top-tag {
    padding: 7px 12px;
    background-color: #ECDFCE;
    border-radius: 4px;
    font-size: 12px;
    font-family: "Raleway-Medium", "sans-serif";
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--thumbnail {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    &-img {
      width: 100%;
      height: 300px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &--brand {
    font-size: 20px;
    font-family: Roboto-Regular, "sans-serif";
    margin-bottom: 10px;
  }

  &--title {
    font-size: 24px;
    font-family: Raleway-Bold, "sans-serif";
    margin-bottom: 15px;
  }

  &--colors {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    margin-bottom: 10px;

    &--color {
      width: 24px;
      height: 24px;
      transition: border-color .3s;
      border: 3px solid transparent;
      border-radius: 100%;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        border-color: rgba(5, 20, 49, 0.2);
      }

      &.active {
        border-color: #051431;
      }

      div {
        width: 100%;
        height: 100%;
      }
    }
  }

  &--colors-empty {
    width: 100%;
    height: 34px;
  }

  &--price {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 28px;
    font-family: Raleway-Bold, "sans-serif";
    color: #051431;
    margin-bottom: 10px;

    &--discount {
      font-size: 24px;
      opacity: .5;
      text-decoration: line-through;
      margin-right: 10px;
      color: #000;
    }
  }

  &--monthly {
    font-family: "Roboto-Regular", "sans-serif";
    font-size: 14px;

    .affirm-modal-trigger {
      text-decoration: underline;
      color: #0a265c;
      font-family: Raleway-Bold, "sans-serif";
    }
  }
}
