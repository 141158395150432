@import 'src/assets/styles/index.scss';
@import 'src/assets/styles/mixins/container.scss';
@import 'src/assets/styles/mixins/media.scss';

.landing-page-container {
  @include content-container();
  min-height: 50vh;

  .spinner-wrapper {
    display: flex;
    justify-content: center;
  }

  &__header {
    font-family: 'Raleway-Bold', sans-serif;
    font-size: 2.6rem;
    text-align: center;
    margin-top: 48px;
    margin-bottom: 10px;

    @include below-breakpoint(xs) {
      font-size: 2rem;
      margin-bottom: 48px;
    }
  }

  &__sub {
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 1.8rem;
    color: #051431;
    text-align: center;
    padding: 0 15px;
    margin-bottom: 10px;
  }

  &__content {
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 1.3rem;
    color: #051431;
    text-align: center;
    padding: 0 15px;
    margin-bottom: 40px;

    p {
      font-family: 'Roboto-Regular';
      font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (1920 - 375)));
      padding-top: calc(20px + (30 - 20) * ((100vw - 375px) / (1920 - 375)));
      line-height: normal;

      h3, h2, h1, strong {
        font-family: Raleway-Bold,sans-serif;
        font-size: 2.25rem;
        margin-bottom: 10px;
        margin-top: 20px;
      }

      a {
        color: $primary-red;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    h3, h2, h1 {
      font-family: 'Roboto-Medium';
      margin-bottom: 5px;
      line-height: normal;
    }

    ul {
      list-style: disc;
      margin: 20px auto 20px 40px;
    }

    iframe {
      margin: 0 auto;
      display: block;
      max-width: 100%;
    }
  }

  &__oversea {
    color: #bbb;
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
}
