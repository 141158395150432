@import 'src/assets/styles/index.scss';

.vpn-app-links-container {
  h3 {
    font-family: "Raleway-Bold", sans-serif;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 20px;
  }
}

.vpn-app-links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .vpn-app-link-item {
    display: flex;
    flex-direction: column;
    width: 200px;
    background-color: #f9f9f9;
    box-shadow: 0 1px 1px #ccc;
    border-radius: 12px;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
    margin-right: 3%;
    padding: 10px;

    .vpn-app-link-image {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      height: auto;
      width: 80%;
      margin-bottom: 10px;
    }

    .vpn-app-link-item-text {
      font-family: "Roboto-Regular", sans-serif;
    }
  }
}

.vpn-user-container {
  margin-bottom: 40px;
  h3 {
    font-family: "Raleway-Bold", sans-serif;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 20px;
  }

  .vpn-nouser, .vpn-user {
    background-color: #fff;
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 12px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-family: "Roboto-Regular", sans-serif;
      margin-bottom: 20px;
    }

    .vpn-nouser-button {
      @include button-red();
    }

    .vpn-user-reset-button {
      @include button-white-blue();
    }

    form {
      width: 100%;
    }
  }
}

.vpn-user-input {
  margin-top: 40px;
  width: 100%;
}

.vpn-modal-title {
  font-family: 'Roboto-Medium';
  font-size: 20px;
  line-height: 32px;
}

.vpn-password-popup {
  width: 500px;
  max-width: 95vw;

  .popup__content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    form {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}

.vpn-password-button {
  @include button-red();
  margin-top: 20px;
}
