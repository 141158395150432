@import 'src/assets/styles/index.scss';

.hwl-container {
  @include content-container();
  margin-top: 30px;
  margin-bottom: 40px;
}

.hwl-title {
  font-size: 44px;
  color: #051431;
  margin-bottom: 25px;
  font-family: Raleway-Bold, sans-serif;
}

.hwl-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hwl-filters-container {
  width: 360px;
}

.hwl-products-container {
  width: calc(100% - 380px);
  margin-top: 40px;
}

@media (max-width: 1370px) {
  .hwl-filters-container {
    width: 300px;
  }

  .hwl-products-container {
    width: calc(100% - 320px);
  }
}

@media (max-width: 1220px) {
  .hwl-container {
    padding: 0 40px;
  }

  .hwl-filters-container {
    width: 250px;
  }

  .hwl-products-container {
    width: calc(100% - 270px);
  }
}

@media (max-width: 991px) {
  .hwl-products-container {
    width: 100%;
    margin-top: 0;
  }
}
