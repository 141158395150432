@import 'src/assets/styles/mixins/media.scss';

.outer-activation-form__col {
  width: 100%;
}

.outer-activation-form__input {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 68px;

    @include below-breakpoint(sm) {
      margin-bottom: 44px;
    }
  }
}

.outer-activation-form__info-label {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.outer-activation-form__info-button {
  cursor: pointer;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }

  &-text {
    font-family: "Raleway-Bold", sans-serif;
    margin-left: 10px;
    text-decoration: underline;
    font-size: 14px;
  }
}

.popup-outer-activation-tip {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  &__image {
    width: 400px;
    max-width: 100%;
  }

  &__text {
    font-family: Raleway-Bold, "sans-serif";
    font-size: 18px;
  }
}
