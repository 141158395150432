.hwd-side-infos {
  width: 100%;

  &--title {
    font-family: Raleway-Bold, 'sans-serif';
    font-size: 36px;
    color: #051431;
    margin-bottom: 20px;
  }

  &--pills {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 25px;
    max-width: 400px;

    &--title {
      font-family: Roboto-Regular, sans-serif;
      font-size: 20px;
      color: #000;
      margin-bottom: 10px;

      span {
        color: #3c3c3c;
        font-size: 18px;
      }
    }

    &--items {
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }

    &--item {
      padding: 7px 10px;
      background-color: #fff;
      border: 1px solid rgba(5, 20, 49, 0.2);
      border-radius: 6px;
      transition: background-color 0.3s;
      cursor: pointer;
      font-size: 16px;
      font-family: Roboto-Regular, sans-serif;

      &:hover,
      &.active {
        background-color: #ecdfce;
        border-color: #ecdfce;
      }

      &.line-through {
        text-decoration: line-through;
        opacity: 0.6;
      }
    }

    &--plan-item {
      padding: 7px 10px;
      background-color: #fff;
      border: 1px solid rgba(5, 20, 49, 1);
      border-radius: 12px;
      cursor: pointer;
      font-size: 16px;
      font-family: Roboto-Regular, sans-serif;
      width: 110px;
      text-align: center;
      transition: background-color 0.3s, border-color 0.3s;

      small {
        font-size: 80%;
      }

      span {
        display: block;
        margin-top: 3px;
        font-size: 80%;
      }

      &:hover,
      &.active {
        background-color: #ecdfce;
        border-color: #ecdfce;
      }
    }

    &--colors-item {
      width: 25px;
      height: 25px;
      border-radius: 100%;
      cursor: pointer;
      border: 3px solid transparent;
      transition: border-color 0.3s;

      &.active {
        border-color: #051431;
      }

      span {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 100%;
      }
    }
  }

  &--plan-selector {
    margin-bottom: 20px;
  }

  &--price {
    display: flex;
    align-items: center;
    font-family: Raleway-Bold, 'sans-serif';
    font-size: 40px;
    color: #051431;
    margin-bottom: 15px;
    grid-gap: 10px;

    &-right {
      display: flex;
      flex-direction: column;
      grid-gap: 2px;
      justify-content: center;

      &-top {
        font-size: 18px;
        &.active {
          color: green;
        }
      }

      &-bottom {
        font-size: 16px;
        color: #666;
        font-weight: 600;
      }
    }
  }

  &--bd-price {
    margin-right: 15px;
    font-size: 36px;
    opacity: 0.5;
    text-decoration: line-through;
  }

  &--monthly {
    font-family: Roboto-Regular, 'sans-serif';
    font-size: 18px;
    margin-bottom: 20px;

    b {
      font-family: Raleway-Bold, 'sans-serif';
      font-size: 20px;
    }

    img {
      height: 25px;
      width: auto;
      display: inline-block;
      margin: 0 7px;
    }

    a {
      font-size: 16px;
      font-family: Roboto-Black, 'sans-serif';
      text-decoration: underline;
      color: #d75f5c;
    }
  }

  &--buttons {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    @media (max-width: 1200px) {
      align-items: center;
    }
  }

  &--add-to-basket,
  &--affirm-button {
    width: 100%;
    max-width: 300px;
    padding: 10px 10px;
    height: 50px;
    background-color: #d75f5c;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: Roboto-Black, 'sans-serif';
    color: #fff;
    transition: transform 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }

  &--affirm-button {
    background-color: #fff;
    border: 1px solid #051431;
    color: #051431;
  }
}
