.hwd-images {
  width: 100%;

  &--title {
    font-family: Raleway-Bold, "sans-serif";
    font-size: 24px;
    color: #051431;
    margin-bottom: 20px;
  }

  &--big {
    width: 100%;
    height: 700px;
    background-color: #F4F5F5;
    border-radius: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media (max-width: 1200px) {
      height: 500px;
    }

    @media (max-width: 600px) {
      height: 350px;
    }
  }

  &--prev, &--next {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
    left: 30px;
    z-index: 3;

    img {
      width: 24px;
      height: auto;
      transform: rotate(-90deg);
    }
  }

  &--next {
    left: auto;
    right: 0;
    img {
      transform: rotate(90deg);
    }
  }

  &--big-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: left .4s;
    transition-timing-function: ease-out;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 90%;
      max-height: 90%;
      width: auto;
      height: 100%;
    }
  }

  &--thumbnails {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    grid-gap: 20px;
    margin-top: 20px;
  }

  &--thumbnail {
    width: 200px;
    height: 140px;
    background-color: #F4F5F5;
    border: 1px solid #F4F5F5;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: border-color .3s;

    @media (max-width: 1200px) {
      width: 140px;
      height: 110px;
    }

    @media (max-width: 600px) {
      width: 110px;
      height: 90px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: 100%;
    }

    &.active {
      border-color: rgba(5, 20, 49, 0.2);
    }
  }
}
