.pw-reason {
  padding: 100px 100px;

  &--title {
    font-family: Raleway-Bold, "sans-serif";
    font-size: 36px;
    color: #051431;
    margin-bottom: 35px;
  }

  &--content {
    width: 100%;
    display: flex;
    grid-gap: 10px;

    &-left {
      display: flex;
      flex-direction: column;
      flex: 1 70%;
    }

    &-right {
      display: flex;
      flex-direction: column;
      flex: 1 30%;
    }
  }

  &--boxes {
    display: flex;
    grid-gap: 2%;
    margin-bottom: 25px;
  }

  &--box {
    border-radius: 10px;
    border: 1px solid rgba(5, 20, 49, 0.2);
    height: 175px;
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color .3s, border-color .3s;

    &:hover {
      background-color: rgba(#F1E9DA, .5);
    }

    &.active {
      background-color: #F1E9DA;
      border-color: #F1E9DA;

      .pw-reason--box-title {
        display: none;
      }
      .pw-reason--box-image {
        display: block;
      }
    }
  }

  &--box-title {
    text-align: center;
    text-transform: uppercase;
    line-height: 28px;
    font-family: Raleway-Bold, "sans-serif";
    font-size: 20px;
    color: #051431;
  }

  &--box-image {
    display: none;
    width: 60px;
    height: auto;
  }

  &--reason-content {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    &--title {
      font-family: Raleway-Bold, "sans-serif";
      color: #051431;
      font-size: 20px;
    }

    &--content {
      font-family: Roboto-Regular, "sans-serif";
      font-size: 18px;
      line-height: 28px;
    }
  }

  &--connect-button {
    padding: 20px 45px;
    color: #fff;
    border-radius: 40px;
    background-color: #D75F5C;
    width: auto;
    font-family: Roboto-Black, "sans-serif";
    font-size: 16px;
  }

  &--slider {
    width: 100%;
    height: 500px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    &::before {
      background: linear-gradient(360deg, rgba(28, 23, 55, 0.5) -2.61%, rgba(228, 207, 207, 0) 134.13%);
    }
  }

  &--slider-item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    transition: left .35s;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &--slider-next {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Raleway-Bold, "sans-serif";
    font-size: 20px;
    color: #fff;
    grid-gap: 15px;
    cursor: pointer;
    background-color: rgba(#051431, .5);
    transition: background-color .3s;

    &:hover {
      background-color: rgba(#051431, .8);
    }

    img {
      height: 15px;
      width: auto;
    }
  }

  &--slider-index {
    position: absolute;
    right: 15px;
    bottom: 20px;
    font-size: 26px;
    font-family: Roboto-Regular, "sans-serif";
    color: #fff;

    small {
      font-size: 18px;
    }
  }

  &--mobile-boxes {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
  }

  &--mobile-box {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
  }

  &--mobile-box-image {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background: #F1E9DA;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 30px;
      width: auto;
    }
  }

  &--mobile-box-title {
    font-size: 18px;
    font-family: Raleway-Bold, "sans-serif";
    color: #051431;
  }

  &--mobile-box-content {
    font-size: 15px;
    font-family: Roboto-Regular, "sans-serif";
    color: #000;
  }

  @media (max-width: 1200px) {
    &--content {
      &-left {
        flex: 1 65%;
      }

      &-right {
        flex: 1 35%;
      }
    }

    &--box-title {
      font-size: 16px;
    }
  }

  @media (max-width: 1030px) {
    &--content {
      flex-direction: column;
      &-left {
        flex: 1 100%;
      }

      &-right {
        flex: 1 100%;
      }
    }
  }

  @media (max-width: 780px) {
    padding: 30px;

    &--title {
      font-size: 26px;
    }
  }
}
