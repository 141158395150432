@import 'src/assets/styles/index.scss';

.styled-input {
  position: relative;

  &__label {
    position: absolute;
    bottom: 100%;
    font-family: "Roboto-Medium", sans-serif;
    font-size: 1rem;
    margin-bottom: 4px;
  }

  &__input {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1.125rem;
    border-radius: 10px;
    padding: 18px 16px;
    border: 1px solid $input-border;
    width: 100%;

    &.invalid {
      border-color: $primary-red;
    }

    &:focus-visible {
      border-color: $primary-dark-blue;
    }

    &:disabled {
      background-color: $light-grey;
    }

    &.error {
      border-color: $primary-red;
    }
  }

  &__toggle-visibility-button {
    position: absolute;
    right: 20px;
    top: 29.5px;
    transform: translateY(-50%);
  }

  &__description {
    margin-top: 4px;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1rem;
    color: $input-description;
    text-align: start;

    &.error {
      color: $primary-red;
    }
  }

  &__select-wrapper {
    position: relative;
  }

  &__select-arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    right: 10px;
    top: 50%;
  }
}
