$primary-red: #d75f5c;
$primary-dark-red: #cf413d;
$primary-yellow: #fda813;
$primary-dark-blue: #051431;
$primary-black: #191919;
$light-red: #fd8d78;
$light-yellow: #ffe8ad;
$light-blue: #9fcdff;
$light-brown: #ecdfce;
$label: #ffffff;
$label-reverse: #000000;
$light-grey: #eeeeee;
$light-dark-grey: #9c9c9c;
$light-darkness-grey: #838383;
$light-button-red: #fec6bb;
$grey-line: #323131;
$light-grey-line: #c4c4c4;
$input-border: #dcdce4;
$input-description: #878787;
$dark-blue: #0a265c;
$black-dark-blue: #000d25;
$light-white-grey: #fafafa;
$white-grey: #e5e5e5;
$transparent: #00000000;
$basket-price: #f7f7f7;
$basket-item-footer: #f4ebdf;
$beige: #eddfcd;
$card-back-side: #f0f0f0;
$light-gray: #f6f6f6;
$dark-grey: #727272;
$light-black: #3d3d3d;
