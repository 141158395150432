@import 'src/assets/styles/index.scss';

.hwd-container {
  @include content-container();
  margin-top: 30px;
  margin-bottom: 40px;
}

.hwd-spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.hwd-sides {
  display: flex;
  grid-gap: 20px;
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  &--left {
    flex: 1 65%;

    @media (max-width: 1200px) {
      flex: 1 100%;
    }
  }

  &--right {
    flex: 1 35%;

    @media (max-width: 1200px) {
      flex: 1 100%;
    }
  }
}

.hwd-tabbed-infos-container {
  margin-bottom: 60px;
}
