.tax {
  &--container {
    margin-bottom: 10px;
    background-color: #eee;
    padding: 10px;
    border-radius: 10px;

    .basket__price-row {
      cursor: pointer;
    }
  }

  &--tax-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Lato-Regular, sans-serif;
    font-size: 12px;
    width: 100%;
    align-items: center;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
  }

  &--tax-item-col {
    max-width: 60%;
  }
}
