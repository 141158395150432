@import 'src/assets/styles/index.scss';

.account-screen__section {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.account-screen-section-container {
  width: 100%;
  max-width: 748px;
  margin: auto;

  @include below-breakpoint(sm) {
    max-width: 100%;
  }
}

.account-screen-section-title {
  @include header-three();

  font-size: calc(24px + (36 - 24) * ((100vw - 375px) / (1920 - 375)));

  @include below-breakpoint(sm) {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
}
