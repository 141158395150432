@import 'src/assets/styles/index.scss';

.account-edit-otp-verification {
  min-width: 327px;
  width: 100%;
  max-width: 629px;

  @include below-breakpoint(sm) {
    max-width: 95%;
  }

  @include below-breakpoint(xs) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__container {
    padding: 0 calc(61.5 * ((100vw - 375px) / (1920 - 375)));
    border-radius: 10px;
  }

  &__title {
    text-align: center;
    font-family: 'Raleway-Bold';
    font-size: calc(20px + (28 - 20) * ((100vw - 375px) / (1920 - 375)));
    line-height: calc(28px + (36 - 28) * ((100vw - 375px) / (1920 - 375)));
    color: $primary-dark-blue;

    @include below-breakpoint(xs) {
      padding: 0 5px;
    }
  }

  &__loader-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  &__form {
    margin-top: 0px;
  }

  &__form-error {
    color: $primary-red;
    font-family: Raleway-Regular, "sans-serif";
    font-size: 13px;
    margin-left: 15px;
    margin-top: 10px;
  }

  &__btn {
    @include button-red();

    margin: 24px auto 0;
  }

  &__qr {
    width: 250px;
    height: 250px;
    margin: 10px auto 20px;
  }
}
