@mixin content-container() {
  width: 100%;
  padding: 0 calc(24px + (200 - 24) * ((100vw - 375px) / (1920 - 375)));
}

@mixin content-container-shadow() {
  padding: 24px;
  box-shadow: 0 4px 8px 0 #00000040;
  border-radius: 10px;
}
