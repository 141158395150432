@import 'src/assets/styles/index.scss';

.account-screen-edit {
  position: relative;
  width: calc(100% - 300px);
  max-width: 748px;
  min-height: 447px;
  padding-bottom: calc(48px + (126 - 48) * ((100vw - 375px) / (1980 - 375)));

  @media (max-width: 992px) {
    width: 100%;
  }
}

.account-screen-edit__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
}
