.pwt-protect {
  display: flex;
  padding: 20px 100px;
  grid-gap: 20px;
  margin-bottom: 100px;

  &--left-side {
    flex: 1 50%;
  }

  &--right-side {
    flex: 1 50%;
    display: flex;
    align-items: center;
  }

  &--title {
    font-size: 34px;
    font-family: Raleway-Bold, "sans-serif";
    line-height: 46px;
    margin-bottom: 40px;

    span {
      color: #D75F5C;
    }
  }

  &--list {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }

  &--list-item {
    width: 100%;
    position: relative;
    padding-left: 40px;
  }

  &--list-item-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: auto;
  }

  &--list-item-title {
    font-family: Raleway-Bold, "sans-serif";
    font-size: 20px;
    color: #051431;
    margin-bottom: 5px;
  }

  &--list-item-content {
    font-family: Roboto-Regular, "sans-serif";
    font-size: 16px;
    color: #000;
  }

  &--banner {
    width: 100%;
    height: auto;
  }

  @media (max-width: 890px) {
    flex-direction: column;

    &--left-side, &--right-side {
      flex: 1 100%;
    }

    &--right-side {
      justify-content: center;
    }

    &--banner {
      max-height: 400px;
      max-width: 100%;
      width: auto;
    }
  }

  @media (max-width: 600px) {
    padding: 20px;
    margin-bottom: 40px;

    &--title {
      font-size: 26px
    }

    &--banner {
      max-height: 300px;
    }
  }
}
