@import 'src/assets/styles/index.scss';

.account-layout {
  @include content-container();
}

.account-layout-container {
  width: 100%;
  display: flex;
  align-items: flex-start;

  @include below-breakpoint(md) {
    flex-direction: column;
    align-items: center;
  }
}

.account-layout__account-select {
  margin-right: 24px;

  @include below-breakpoint(md) {
    margin: 0 0 24px 0;
  }
}
