@import 'src/assets/styles/colors.scss';

.change-plan--popup {
  width: 90%;
}

.change-plan--title {
  font-family: "Raleway-Bold", sans-serif;
  position: absolute;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  font-size: 20px;
}

.change-plan-wrapper {
  .card {
    width: calc(50% - 25px);
    padding: 15px;
    margin-bottom: 20px;

    &:hover {
      background-color: $label;
      color: $primary-dark-blue;
    }
  }

  .card__title {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.change-plan--spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
