@import '../../../../assets/styles/index.scss';
@import '../../../../assets/styles/mixins/heading.scss';
@import '../../../../assets/styles/mixins/button.scss';

.header-two {
  @include header-two();
  text-align: center;

  span {
    color: $primary-red;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -10px;
      width: 120px;
      height: 4px;
      border-radius: 2px;
      background-color: $primary-red;
      left: calc(50% - 20px);
      transform: translateX(-50%);

      @media (max-width: 480px) {
        display: none;
      }
    }
  }
}

.tariffs-container {
  margin: 0 auto;
  padding: 0 12px;
}

.tariffs-amount {
  margin: 0 auto;
}
.tariffs-content {
  margin: calc(48px + (80 - 48) * ((100vw - 375px) / (1920 - 375))) 0;
}
.tariffs__label {
  font-family: 'Roboto-Regular', sans-serif;
  display: block;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin: 12px auto 0 auto;
  width: fit-content;
}

.tariffs__card {
  width: fit-content;
  display: flex;
  margin: calc(25px + (80 - 48) * ((100vw - 375px) / (1920 - 375))) auto;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.tariffs__button {
  @include button-blue();
  margin: 0 auto;
  @media (max-width: 650px) {
    width: 70%;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}
