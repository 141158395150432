.bdp {
  &--selector {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #eee;
    border-radius: 12px;
    padding: 5px 15px;

    &--item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      cursor: pointer;

      &--check {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 1px solid #999;
        position: relative;
        margin-right: 10px;

        &::after {
          content: '';
          width: 75%;
          height: 75%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: transparent;
          transition: background-color .3s;
          border-radius: 20px;
        }
      }

      &--label {
        font-family: Roboto-Regular, "sans-serif";
        font-size: 14px;
        color: #000;
        flex: 1;
      }

      &--price {
        font-family: Raleway-Bold, "sans-serif";
        font-size: 16px;
        color: #000;
        margin-left: 10px;
      }

      &.active {
        .bdp--selector--item {
          &--check {
            border-color: #d75f5c;
            &::after {
              background-color: #d75f5c;
            }
          }
        }
      }
    }
  }
}
