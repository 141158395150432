@import 'src/assets/styles/index';
@import 'src/assets/styles/mixins/media';

.faq-list {
}

.faq-list__list {
  columns: 2;
  height: 100%;

  @include below-breakpoint(sm) {
    columns: 1;
  }
}

.faq-list__search {
  margin-bottom: 48px;
}

.faq-list__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0!important;
}

.faq-list__list-item {
  display: inline-block;
  margin-bottom: 24px;
  width: 50%;
  padding: 0 10px;

  @include below-breakpoint(sm) {
    width: 100%;
  }
}

.faq-list__list--empty {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}
